import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import FormEditor from "../../components/Base/FormEditor";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { saveApiData, fetchApiData } from "../../request/config/index";
import { GET_NEED_HELP, SAVE_NEED_HELP } from "../../request/config/Urls";

const Help = () => {
  const dispatch = useDispatch();
  const [needHelpEmail, setHelpEmail] = useState("");
  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async () => {
    try {
      let res = await saveApiData({
        url: SAVE_NEED_HELP,
        body: { description: needHelpEmail },
      });
      dispatch(alertSuccess(`Support Email Added Successfully`));
      if (res) {
        fetchData();
      }
    } catch (error: any) {
      console.error(
        `${error?.response?.data?.message || "Error saving data:"}`
      );
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error saving data:"}`
        )
      );
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetchApiData({ url: GET_NEED_HELP });
      setHelpEmail(response.data[0] ? response.data[0]?.description : "");
    } catch (error: any) {
      console.error(
        `${error?.response?.data?.message || "Error fetching data:"}`
      );
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data:"}`
        )
      );
    }
  };
  return (
    <React.Fragment>
      <div>
        <Col xs={12}>
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-3">
                <FormEditor data={needHelpEmail} setData={setHelpEmail} />
              </div>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-info w-md"
              onClick={() => onSubmit()}
            >
              Save
            </button>
          </div>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default Help;
