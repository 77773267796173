import { FormControl } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field';
import useStyles from '../style';

const FullyDelutedValuationComponent = (props: any) => {
  const classes = useStyles();
  const { register, setValue, errors, poolDetail, control, watch } = props;
  const [fullyDelutedValuation, setFullyDelutedValuation] = useState("");

  useEffect(() => {
    if (poolDetail && poolDetail.fdv) {
      console.log(poolDetail?.fdv);
      setFullyDelutedValuation(poolDetail.fdv);
    }
  }, [poolDetail, poolDetail?.token]);

  const handleChange = (value: any, name: any) => {
    setFullyDelutedValuation(value);
    setValue("fullyDelutedValuation", value);
  };

  return (
    <>
      <br />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>
            Fully Deluted Valuation
          </label>
          <CurrencyInput
            id="fullyDelutedValuation"
            placeholder="Please enter value"
            value={fullyDelutedValuation}
            decimalsLimit={2}
            onValueChange={handleChange}
            className={`${classes.formInputBox}`}
            // disabled={isDeployed}
          />
          <input
            type="hidden"
            name="fullyDelutedValuation"
            value={fullyDelutedValuation || ""}
            ref={register({})}
          />
        </FormControl>
      </div>
    </>
  );
}

export default FullyDelutedValuationComponent;