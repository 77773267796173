import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  loader: {
    border: "4px solid #f3f3f3" /* Light grey */,
    borderTop: "4px solid #FFCC00" /* Blue */,
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    animation: "$spin 2s linear infinite",
    marginLeft: "10px",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

export default useStyles;
