const REFERENCE_USERS_LIST_LOADING = 'REFERENCE_USERS_LIST_LOADING';
const REFERENCE_USERS_LIST_SUCCESS = 'REFERENCE_USERS_LIST_SUCCESS';
const REFERENCE_USERS_LIST_FAILURE = 'REFERENCE_USERS_LIST_FAILURE';

const TOP_REFERRAL_LOADING = 'TOP_REFERRAL_LOADING';
const TOP_REFERRAL_SUCCESS = 'TOP_REFERRAL_SUCCESS';
const TOP_REFERRAL_FAILURE = 'TOP_REFERRAL_FAILURE';


export const referralActions = {
  REFERENCE_USERS_LIST_LOADING,
  REFERENCE_USERS_LIST_SUCCESS,
  REFERENCE_USERS_LIST_FAILURE,
  TOP_REFERRAL_LOADING,
  TOP_REFERRAL_SUCCESS,
  TOP_REFERRAL_FAILURE,
};
