
import {useEffect, useState} from "react";
import {DEFAULT_LIMIT} from "../../../../constants";
import { useDebounce } from "use-debounce";

const useGetList = (props: any) => {
  const { poolDetail, handleSearchFunction } = props;
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [status, setStatus] = useState(-1);

  const [query, setQuery] = useState('');
  const [failure, setFailure] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCampaignSearch = (event: any) => {
    setCurrentPage(1);
    setQuery(event.target.value);
  };
  const [searchDelay] = useDebounce(handleCampaignSearch, 500);
  const search = () => {
    console.log('SEARCH Query: ', query );
    if (poolDetail && poolDetail.id) {
      const searchParams = {
        search_term: query,
        page: currentPage,
        limit: DEFAULT_LIMIT,
        status: status != -1 ? status : null,
      };
      handleSearchFunction &&
      handleSearchFunction(poolDetail.id, searchParams)
        .then((res: any) => {
          if (res?.status !== 200) {
            setFailure(true);
            return [];
          } else {
            let response = res.data || {};
            setFailure(false);
            setLastPage(response.lastPage || 1);
            setCurrentPage(response.page || 1);
            setTotalRecords(response.total || 1);

            let newData = response.data || response?.reportedIssues ||  [];
            setRows(newData);

            return newData;
          }
        });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      search();
    }, 500);
    return () => clearTimeout(timer);
  }, [poolDetail, currentPage, query, status]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  return {
    rows, setRows,
    search, searchDelay,
    failure, loading,
    lastPage, currentPage, totalRecords, setCurrentPage,
    handlePaginationChange,
    status,
    setStatus
  }
};


export default useGetList;




