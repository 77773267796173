import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import FormEditor from "../../components/Base/FormEditor";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { fetchApiData } from "../../request/config/index";
import { GET_PRIVACY_POLICY } from "../../helper/url_helper";
import { saveApiData } from "../../request/config/index";
import { SAVE_PRIVACY_POLICY } from "../../request/config/Urls";

const PrivacyPolicyComponent = () => {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [privacyPolicy, setPrivacyPolicyData] = useState("");
  useEffect(() => {
    fetchData();
  }, []);

  const dispatch = useDispatch();

  const onSubmit = async (event: any) => {
    try {
      let res = await saveApiData({
        url: SAVE_PRIVACY_POLICY,
        body: { privacy_policy: privacyPolicy },
      });
      console.log("privacy Data: " + res);
      if (res) {
        dispatch(alertSuccess("Updated Successfully"));
        fetchData();
      }
    } catch (error: any) {
      dispatch(alertFailure("error:" + error));
    }
  };
  const fetchData = async () => {
    try {
      const response = await fetchApiData({ url: GET_PRIVACY_POLICY });
      setPrivacyPolicyData(
        response.data[0] && response.data[0]?.privacy_policy
          ? response.data[0]?.privacy_policy
          : ""
      );
    } catch (error: any) {
      console.error(
        `${error?.response?.data?.message || "Error fetching data:"}`
      );
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data:"}`
        )
      );
    }
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card>
            <CardBody className="p-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <h4 className="card-title mb-1">Enter Privacy Policy</h4>
                  </div>
                  <FormEditor
                    data={privacyPolicy}
                    setData={setPrivacyPolicyData}
                  />
                </div>
                <div>
                  <button type="submit" className="btn btn-info w-md mt-3">
                    Submit
                  </button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PrivacyPolicyComponent;
