import React from 'react'
import ConfirmDialog from "../../../../components/Base/ConfirmDialog";
import useStyles from "../../style";

const TableWinnerAllForm = (props: any) => {
  const classes = useStyles();
  const {
    openEditPopup,
    setOpenEditPopup,
    handleAllPassword,
    setPassword,
    password,
  } = props;
  return (
    <>
      <ConfirmDialog
        title={"Enter Password"}
        open={openEditPopup}
        onConfirm={handleAllPassword}
        onCancel={() => {
          setOpenEditPopup(false);
        }}
        confirmLoading={false}
      >
        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>Password</label>
          <div>
            <input
              placeholder="Please enter password"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              className={`${classes.formInputBox}`}
              style={{ width: "100%" }}
              type="password"
              // disabled={isDeployed}
            />
          </div>
        </div>
      </ConfirmDialog>
    </>
  );
}

export default TableWinnerAllForm