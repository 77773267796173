import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  Row,
  Table,
} from "reactstrap";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import moment from "moment";
import { truncateTextBetween } from "../../helper/functions";
import useStyles from "./style";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import {
  deleteApiData,
  fetchApiData,
  saveApiData,
  updateApiData,
  updateData,
} from "../../request/config/index";
import {
  DELETE_SOCIAL_MEDIA_LINKS,
  GET_SOCIAL_MEDIA_LINKS,
  GET_SOCIAL_MEDIA_TYPES,
  SAVE_SOCIAL_MEDIA_LINKS,
  SORT_SOCIAL_MEDIA_LINKS,
  UPDATE_SOCIAL_MEDIA_LINKS,
} from "../../request/config/Urls";

interface SocialMediaType {
  id?: number;
  sm_type?: string;
  created_at?: string | null;
  updated_at?: string | null;
}

type SocialMediaLink = {
  id: number;
  social_media_type_id: number;
  sm_link: string;
  sort_id: null | number;
  created_at: string;
  updated_at: string;
  sm_type?: string;
};

interface SocialMedia {
  id?: number;
  sm_type?: string;
  created_at?: string | null;
  updated_at?: string | null;
  socialMediaLinks?: SocialMediaLink[];
}

const SocialMediaComponent = () => {
  const dispatch = useDispatch();
  const [socialMediaLinks, setSocialMediaLinks] = useState<SocialMediaLink[]>(
    []
  );
  const [socialMediaType, setSocialMediaType] = useState<SocialMedia[]>([]);
  const [formData, setFormData] = useState({
    link: "",
    type: 1,
    id: undefined,
  });
  const [id, setId] = useState(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const fetchSocialMedia = async () => {
    try {
      const typeResponse = await fetchApiData({ url: GET_SOCIAL_MEDIA_TYPES });
      const linkResponse = await fetchApiData({ url: GET_SOCIAL_MEDIA_LINKS });
      setSocialMediaLinks(
        linkResponse?.data
          .reduce(
            (acc: any, smType: any) => [
              ...acc,
              ...smType.socialMediaLinks.map((link: any) => ({
                ...link,
                sm_type: smType.sm_type,
              })),
            ],
            [] as SocialMediaLink[]
          )
          .sort((a: any, b: any) => a.sort_id - b.sort_id)
      );
      setSocialMediaType(typeResponse?.data);
    } catch (error: any) {
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data"}`
        )
      );
    }
  };

  const saveLink = async () => {
    let body: any = {
      social_media_type_id: formData.type,
      sm_link: formData.link,
    };
    const url = `${UPDATE_SOCIAL_MEDIA_LINKS}/${formData.id}`;
    try {
      if (formData.id) {
        await updateData({ url, body });
      } else {
        body["sort_id"] = socialMediaLinks.length + 1;
        await saveApiData({ url: SAVE_SOCIAL_MEDIA_LINKS, body });
      }
      fetchSocialMedia();
      setFormData({ link: "", type: 1, id: undefined });
      dispatch(alertSuccess("Link Saved"));
    } catch (error: any) {
      dispatch(
        alertFailure(`${error?.response?.data?.message || "Error saving data"}`)
      );
    }
  };

  const editLink = (link: any) => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setFormData({
      id: link.id,
      link: link.sm_link,
      type: link.social_media_type_id,
    });
  };

  const deleteLink = async () => {
    try {
      const url = `${DELETE_SOCIAL_MEDIA_LINKS}/${id}`;
      await deleteApiData({ url });
      dispatch(alertSuccess("Link deleted"));
      fetchSocialMedia();
      setDeleteModalOpen(false);
    } catch (error: any) {
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error deleting data"}`
        )
      );
    }
  };

  const handleDeleteModal = (id: any) => {
    setId(id);
    setDeleteModalOpen(true);
  };

  useEffect(() => {
    fetchSocialMedia();
  }, []);

  const updateSorting = async (updatedData: any) => {
    try {
      const response: any = await updateApiData({
        url: SORT_SOCIAL_MEDIA_LINKS,
        body: { social_media_liks: updatedData },
      });
      if (response.status === 200) {
        dispatch(alertSuccess("Updated Successfully"));
      } else {
        dispatch(alertFailure(response?.message));
      }
    } catch (error: any) {
      console.error(`${error?.message || "Error Updating"}`);
      dispatch(alertFailure(`${error?.message || "Error Updating"}`));
    }
  };

  const onDragEnd = (result: any) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items = Array.from(socialMediaLinks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      id: item?.id,
      sort_id: index + 1,
    }));
    updateSorting(data);
    setSocialMediaLinks(items);
  };

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="p-4">
              <div className="row">
                <div className="col-sm-3">
                  <label className="form-label">Select Type</label>
                  <select
                    className="form-control"
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                  >
                    {socialMediaType.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.sm_type}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-sm-9">
                  <div className="mb-3">
                    <label
                      htmlFor="basicpill-firstname-input"
                      className="form-label mt-1"
                    >
                      Enter Link
                    </label>
                    <input
                      type="text"
                      name="link"
                      value={formData.link}
                      className="form-control"
                      id="basicpill-firstname-input"
                      placeholder="Enter URL"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  className="btn btn-info w-md"
                  onClick={saveLink}
                >
                  Save
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12}>
          <Label className="form-label">SocialMedia Table</Label>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>URL</th>
                      <th>Created at</th>
                      <th>Updated at</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {socialMediaLinks?.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <td>{item.sm_type}</td>
                                  <td>{truncateTextBetween(item.sm_link)}</td>
                                  <td>
                                    {moment(item.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {moment(item.updated_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <span className="d-flex">
                                      <button
                                        className="btn btn-info btn-sm me-2"
                                        onClick={() => editLink(item)}
                                      >
                                        Edit
                                      </button>
                                      <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() =>
                                          handleDeleteModal(item.id)
                                        }
                                      >
                                        Delete
                                      </button>
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </div>
            </CardBody>

            <Modal isOpen={deleteModalOpen} size="sm">
              <ModalBody>
                <div className="mb-3">
                  <p>Do you want to delete this?</p>
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    color="secondary"
                    className="me-3"
                    onClick={() => setDeleteModalOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button color="danger" onClick={deleteLink}>
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SocialMediaComponent;
