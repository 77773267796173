import React from "react";
import Breadcrumb from "../../components/Base/BreadCrumbs";
import SocialMediaComponent from "./SocialMediaComponent";
import DefaultLayout from "../../components/Layout/DefaultLayout";

const SocialMediaLinks = () => {
  return (
    <DefaultLayout>
      <div className="page-content">
        <Breadcrumb
          title="SocialMedia Links"
          breadcrumbItem="SocialMedia Links"
        />
        <SocialMediaComponent />
      </div>
    </DefaultLayout>
  );
};

export default SocialMediaLinks;
