import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import {deleteRPCUrl, getRPCUrls, rpcStatusUpdate} from "../../request/rpc";
import { RPCListResponse } from "./rpc.interface";
import {Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper} from "@material-ui/core";
import {Switch} from "antd";
import Button from "@material-ui/core/Button";
import { useHistory } from 'react-router-dom';


const RPCListing = () => {
  const [rpcUrls, setRpcUrls] = useState<RPCListResponse[]>([]);
  const history = useHistory();
  const fetchData = async () => {
    try {
      const response = await getRPCUrls();
      if (response?.status === 200) {
        console.log("success", response);
        setRpcUrls(response?.data);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const updateStatus = async(rpcUrl : RPCListResponse) => {
    try {
      const isActive = rpcUrl?.isActive == 1 ? 0 : 1
      const Id = rpcUrl?.id;
      const response = await rpcStatusUpdate(isActive, Id);
      fetchData();
    } catch (e) {
    }
  }
  
  const deleteRpc = async(rpcUrl: RPCListResponse) => {
    try {
      const response = await deleteRPCUrl(rpcUrl?.id);
      fetchData();
    } catch (e) {
    }
  }
  
  
  useEffect(() => {
    fetchData();
  }, []);
  
  return (
    <DefaultLayout>
      <Button variant="contained" color="primary" style={{marginBottom: "30px"}} onClick={() => { history.push("add-rpc-url") }}>Add RPC URL</Button>
      <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {/*<TableCell>Id</TableCell>*/}
            <TableCell>Network</TableCell>
            <TableCell>URL</TableCell>
            <TableCell>Active</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rpcUrls?.map((rpcUrl) => (
            <TableRow key={rpcUrl.id}>
              {/*<TableCell>{rpcUrl.id}</TableCell>*/}
              <TableCell>{rpcUrl.network}</TableCell>
              <TableCell>{rpcUrl.url}</TableCell>
              <TableCell><Switch onClick={() => {updateStatus(rpcUrl)}} checked={rpcUrl.isActive == 1}/></TableCell>
              <TableCell>
                <Button variant="contained" color="primary" style={{marginRight: "10px"}} onClick={() => { history.push(`edit-rpc-url/${rpcUrl?.id}`) }}>Edit</Button>
                <Button variant="contained" color="secondary" onClick={() => {deleteRpc(rpcUrl)}}>Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
    </DefaultLayout>
  );
};

export default RPCListing;
