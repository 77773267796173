import React from 'react';
import {Input, Label} from 'reactstrap';

const ColorInput = ({label, value, onChange, title} : any) => {
    return (
        <div className="col-md-6 mb-3">
            <Label htmlFor={`${label.toLowerCase()}-color-input`} className="form-label">
                {label}
            </Label>
            <Input
                type="color"
                className="form-control form-control-color w-100"
                id={`${label.toLowerCase()}-color-input`}
                value={value}
                onChange={onChange}
                title={title}
            />
        </div>
    );
};

export default ColorInput;