"use client";
import React, { useState } from "react";
import Help from "./Help";
import { Card, CardBody, CardText, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import NeedHelpGuide from "./NeedHelpGuide";
import NeedHelpFaq from "./NeedHelpFaq";
import Breadcrumb from "../../components/Base/BreadCrumbs";
import DefaultLayout from "../../components/Layout/DefaultLayout";

const NeedHelp = () => {

  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  return (
    <DefaultLayout>
      <div className="page-content">
        <Breadcrumb title="" breadcrumbItem="Need Help" />
        <Card>
          <CardBody>
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Support Email</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-user"></i>
                  </span>
                  <span className="d-none d-sm-block">Guide</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleCustom("3");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-envelope"></i>
                  </span>
                  <span className="d-none d-sm-block">FAQ</span>
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <CardText className="mb-0">
                     <Help/>
                    </CardText>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <CardText className="mb-0">
                      <NeedHelpGuide/>
                    </CardText>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <CardText className="mb-0">
                      <NeedHelpFaq/>
                    </CardText>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    </DefaultLayout>
  );
};

export default NeedHelp;
