import React, { useRef, useState } from "react";
import useStyles from "../../style";
import { renderErrorCreatePool } from "../../../../utils/validate";
import { Controller } from "react-hook-form";
// @ts-ignore
import { Editor } from "@tinymce/tinymce-react";
// @ts-ignore

function RemainingTokens(props: any) {
  const classes = useStyles();
  const { register, setValue, errors, watch, getValues, poolDetail, control } =
    props;
  const renderError = renderErrorCreatePool;
  const isDeployed = !!poolDetail?.is_deploy;
  const editorRef = useRef(null);
  const onChange = (field: any) => {
    if (editorRef.current) {
      const data = editorRef.current.getContent();
      setValue(field.name, data);
    }
  };

    const timyInitConfig = {
      height: 500,
      menubar: true,
      plugins: [
        "advlist",
        "autolink",
        "lists",
        "link",
        "image",
        "charmap",
        "preview",
        "anchor",
        "searchreplace",
        "visualblocks",
        "code",
        "fullscreen",
        "insertdatetime",
        "media",
        "table",
        "code",
        "help",
        "wordcount",
      ],
      toolbar:
        "undo redo | blocks | " +
        "bold italic forecolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | " +
        "removeformat | help",
    };
  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.exchangeRateTitle}>Remaining Tokens</label>
        <Controller
          control={control}
          rules={{
            required: false,
          }}
          name="remaining_tokens"
          render={(field) => {
            return (
              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                onInit={(evt: any, editor) => (editorRef.current = editor)}
                initialValue={poolDetail.remaining_tokens}
                init={timyInitConfig}
                onEditorChange={(evt: any, editor: any) => onChange(field)}
              />
            );
          }}
        />
        <p className={classes.formErrorMessage}>
          {renderError(errors, "remaining_tokens")}
        </p>
      </div>
    </>
  );
}

export default RemainingTokens;
