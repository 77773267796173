//Landing Section
export const SAVE_LANDING_PAGE = "/admin/submit-landing-page";
export const GET_LANDING_PAGE = "/admin/landing-page";

//Register Section
export const GET_REGISTER_PAGE = "/admin/landing-page/register-section";
export const UPDATE_REGISTER_PAGE =
  "/admin/landing-page/update/register-section";
export const SAVE_REGISTER_PAGE = "/admin/landing-page/register-section";
export const DELETE_REGISTER_PAGE = "/admin/landing-page/register-section";
export const SORT_REGISTER_PAGE = "/admin/landing-page/register-section";

// Feature Section
export const GET_FEATURE_PAGE = "/admin/landing-page/feature-section";
export const UPDATE_FEATURE_PAGE = "admin/landing-page/update/feature-section";
export const SAVE_FEATURE_PAGE = "/admin/landing-page/feature-section";
export const DELETE_FEATURE_PAGE = "/admin/landing-page/feature-section";
export const SORT_FEATURE_PAGE = "/admin/landing-page/feature-section";

// Goggle Form Section
export const GET_GOOGLE_FORM_PAGE = "/admin/landing-page/googleform-section";
export const SAVE_GOOGLE_FORM_PAGE = "/admin/landing-page/googleform-section";


// FAQ Page
export const GET_FAQ_CATEGORY_DATA = "admin/faq/question-categories";
export const GET_FAQ_QUESTIONS = "admin/faq/get-faqs";
export const UPDATE_FAQ_QUESTIONS = "admin/faq/update";
export const SAVE_FAQ_QUESTIONS = "admin/faq/submit-faq";
export const DELETE_FAQ_QUESTIONS = "admin/faq/delete";
export const SORT_FAQ_QUESTIONS = "/admin/faq/sort";

// Menu Page
export const GET_MENU_LINKS = "admin/menu/list"
export const UPDATE_MENU_LINKS = "/admin/menu/update"
export const SAVE_MENU_LINKS = "/admin/menu/create"
export const DELETE_MENU_LINKS = "admin/menu/delete"
export const SORT_MENU_LINKS = "admin/menu/sort"

// Tiers Page
export const GET_TIERS_LIST = "/admin/config/tier";
export const UPDATE_TIERS_LIST = "/admin/config/tier";
export const SAVE_TIERS_LIST = "/admin/config/tier";
export const DELETE_TIERS_LIST = "/admin/config/tier";
export const SORT_TIERS_LIST = "/admin/config/tier/sort";
export const GET_TIERS_OVERVIEW = "/admin/config/tier-overview";
export const UPDATE_TIERS_OVERVIEW = "/admin/config/tier-overview";
export const SAVE_TIERS_OVERVIEW = "/admin/config/tier-overview";
export const DELETE_TIERS_OVERVIEW = "/admin/config/tier-overview";
export const SORT_TIERS_OVERVIEW = "/admin/config/tier-overview/sort";
export const GET_TIERS_BENEFITS = "/admin/config/tier-benefits";
export const UPDATE_TIERS_BENEFITS = "/admin/config/tier-benefits";
export const SAVE_TIERS_BENEFITS = "/admin/config/tier-benefits";
export const DELETE_TIERS_BENEFITS = "/admin/config/tier-benefits";
export const SORT_TIERS_BENEFITS = "/admin/config/tier-benefits/sort";
export const GET_STAKING_COMPONENT = "/admin/config/staking-info";
export const UPDATE_STAKING_COMPONENT = "/admin/config/staking-info";
export const SAVE_STAKING_COMPONENT = "/admin/config/staking-info";
export const DELETE_STAKING_COMPONENT = "/admin/config/staking-info";
export const SORT_STAKING_COMPONENT = "/admin/config/tier-benefits/sort";

// Terms of Service
export const GET_TERMS_OF_SERVICES = "/admin/termsofservice";
export const SAVE_TERMS_OF_SERVICES = "/admin/termsofservice";

// Privacy Policy
export const GET_PRIVACY_POLICY = "/admin/privacy-policy/get";
export const SAVE_PRIVACY_POLICY = "/admin/privacy-policy/submit";

// Need Help
export const GET_NEED_HELP = "/admin/need-help/support-email";
export const SAVE_NEED_HELP = "/admin/need-help/support-email";

// Need Help FAQ
export const GET_NEED_HELP_FAQ = "/admin/need-help/faq";
export const UPDATE_NEED_HELP_FAQ = "/admin/need-help/faq";
export const SAVE_NEED_HELP_FAQ = "/admin/need-help/faq";
export const DELETE_NEED_HELP_FAQ = "/admin/need-help/faq";
export const SORT_NEED_HELP_FAQ = "/admin/need-help/faq/sort";

// Need help Guide
export const GET_NEED_HELP_GUIDE = "/admin/need-help/guide";
export const UPDATE_NEED_HELP_GUIDE = "/admin/need-help/guide";
export const SAVE_NEED_HELP_GUIDE = "/admin/need-help/guide";
export const DELETE_NEED_HELP_GUIDE = "/admin/need-help/guide";
export const SORT_NEED_HELP_GUIDE = "/admin/need-help/guide/sort";

// Social Media Links
export const GET_SOCIAL_MEDIA_LINKS = "/admin/social-media-link";
export const GET_SOCIAL_MEDIA_TYPES = "/admin/social-media-type";
export const UPDATE_SOCIAL_MEDIA_LINKS = "/admin/social-media-link";
export const SAVE_SOCIAL_MEDIA_LINKS = "/admin/social-media-link";
export const DELETE_SOCIAL_MEDIA_LINKS = "/admin/social-media-link";
export const SORT_SOCIAL_MEDIA_LINKS = "/admin/social-media-link";

// Theme Options
export const GET_THEME_OPTIONS = "/admin/theme-option";
export const SAVE_THEME_OPTIONS = "/admin/theme-option";