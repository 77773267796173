import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";
import moment from "moment";
import { useForm } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DeleteModal from "../../components/Base/DeleteModal";
import { addNeedHelpFaq } from "../../request/faq";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import {
  deleteApiData,
  fetchApiData,
  updateApiData,
} from "../../request/config/index";
import {
  DELETE_NEED_HELP_FAQ,
  GET_NEED_HELP_FAQ,
  SAVE_NEED_HELP_FAQ,
  SORT_NEED_HELP_FAQ,
} from "../../request/config/Urls";
import FormValidationError from "../../components/Base/FormValidationError";

interface FAQ {
  id?: number;
  faq_title?: string;
  faq_url?: string;
  created_at?: string;
  updated_at?: string;
  sort_id?: number;
}

const defaultValues: FAQ = {
  faq_title: "",
  id: 0,
  faq_url: "",
  created_at: "",
  updated_at: "",
};

const NeedHelpFaq = () => {
  const {
    handleSubmit,
    setValue,
    reset,
    register,
    formState: { errors },
  } = useForm<any>({
    defaultValues,
  });
  const dispatch = useDispatch();
  const [deleteFaqId, setDeleteFaqId] = useState(0);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchApiData({ url: GET_NEED_HELP_FAQ });
      setFaqs(response?.data);
      reset();
    } catch (error: any) {
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data"}`
        )
      );
    }
  }, []);

  const saveFaq = async (values: FAQ) => {
    try {
      const url = deleteFaqId
        ? `${SAVE_NEED_HELP_FAQ}/${deleteFaqId}`
        : `${SAVE_NEED_HELP_FAQ}`;
      const method = deleteFaqId ? "put" : "post";
      const sort_id = deleteFaqId ? values?.sort_id : faqs.length + 1;
      await addNeedHelpFaq(
        url,
        {
          faq_title: values?.faq_title,
          faq_url: values?.faq_url,
          sort_id: sort_id,
        },
        method
      );
      dispatch(alertSuccess("FAQ saved successfully"));
      fetchData();
      setDeleteFaqId(0);
      reset();
    } catch (error: any) {
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error posting data"}`
        )
      );
    }
  };

  const editFaq = (faq: FAQ) => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setValue("faq_title", faq?.faq_title ?? "");
    setValue("faq_url", faq?.faq_url ?? "");
    setDeleteFaqId(faq?.id ?? 0);
  };

  const deleteFaq = async () => {
    try {
      const url = `${DELETE_NEED_HELP_FAQ}/${deleteFaqId}`;
      await deleteApiData({ url });
      dispatch(alertSuccess("FAQ deleted"));
      fetchData();
      setDeleteFaqId(0);
    } catch (error: any) {
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error deleting data"}`
        )
      );
    }
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateSorting = async (updatedData: any) => {
    try {
      const response = await updateApiData({
        url: SORT_NEED_HELP_FAQ,
        body: { support_faq: updatedData },
      });
      if (response.status === 200) {
        dispatch(alertSuccess("Updated Successfully"));
      } else {
        dispatch(alertFailure(response?.message || "Request Failed"));
      }
    } catch (error: any) {
      console.error(`${error?.message || "Error Updating"}`);
      dispatch(alertFailure(`${error?.message || "Error Updating"}`));
    }
  };

  const onDragEnd = (result: any) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items: FAQ[] = Array.from(faqs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      id: item?.id,
      sort_id: index + 1,
    }));
    updateSorting(data);
    setFaqs(items);
  };

  return (
    <div>
      <Row>
        <Col lg={12}>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Title Text</label>
                <input
                  type="text"
                  name="faq_title"
                  className="form-control"
                  placeholder="Enter Title"
                  ref={register({
                    required: "This Field is Required",
                  })}
                />
                <FormValidationError
                  errorMessage={errors?.faq_title?.message ?? ""}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">FAQ URL</label>
                <input
                  type="text"
                  name="faq_url"
                  className="form-control"
                  placeholder="Enter URL"
                  ref={register({
                    required: "This Field is Required",
                  })}
                />
                <FormValidationError
                  errorMessage={errors?.faq_url?.message ?? ""}
                />
              </div>
            </div>
          </div>
          <div>
            <Button onClick={handleSubmit(saveFaq)} color="info">
              Save
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <Card>
            <CardBody>
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>URL</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <tbody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {faqs?.map((item: any, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <td>{item?.id}</td>
                                <td>{item?.faq_title}</td>
                                <td>{item?.faq_url}</td>
                                <td>
                                  {moment(item?.created_at)?.format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item?.updated_at)?.format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  <span className="d-flex">
                                    <Button
                                      className="me-2"
                                      color="info"
                                      size="sm"
                                      onClick={() => editFaq(item)}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      color="danger"
                                      size="sm"
                                      onClick={() => {
                                        setDeleteModalOpen(true);
                                        setDeleteFaqId(item?.id);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </span>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <DeleteModal
        title="FAQ"
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        onDeleteClick={deleteFaq}
      />
    </div>
  );
};

export default NeedHelpFaq;
