import {BaseRequest} from "./Request";
import {apiRoute} from "../utils";

export const getRPCUrls = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/rpc-url`);
  const response = await baseRequest.get(url) as any;
  return await response.json();
};

export const rpcStatusUpdate = async (isActive : number, Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/rpc-url/${Id}`);
  const response = await baseRequest.patch(url, {isActive: isActive}) as any;
  return await response?.json();
}

export const deleteRPCUrl = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/rpc-url/${Id}`);
  const response = await baseRequest.delete(url, {}) as any;
  return await response?.json();
}
export const addRPCUrl = async (rpcUrl: {url: string, network: string}) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/rpc-url`);
  const response = await baseRequest.post(url, rpcUrl) as any;
  return await response?.json();
}

export const updateRPCUrl = async (rpcUrl: {url: string, network: string}, Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/rpc-url/${Id}`);
  const response = await baseRequest.put(url, rpcUrl) as any;
  return await response?.json();
}

export const getRPCUrlById = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/rpc-url/${Id}`);
  const response = await baseRequest.get(url) as any;
  return await response.json();
};