import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import { useForm } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DeleteModal from "../../components/Base/DeleteModal";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import {
  DELETE_TIERS_LIST,
  GET_TIERS_LIST,
  SAVE_TIERS_LIST,
  SORT_TIERS_LIST,
  UPDATE_TIERS_LIST,
} from "../../request/config/Urls";
import {
  deleteApiData,
  fetchApiData,
  saveApiData,
  updateApiData,
  updateData,
} from "../../request/config/index";
import FormValidationError from "../../components/Base/FormValidationError";

interface Tier {
  id: number;
  title: string;
  title_url: string;
  sort_id: number | null;
  created_at: string;
  updated_at: string;
}

const Tiers = () => {
  const [tiers, setTiers] = useState<Tier[]>([]);
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteTierId, setDeleteTierId] = useState(0);
  const [editedData, setEditedData] = useState(null);
  const dispatch = useDispatch();

  const getTierList = async () => {
    const url = GET_TIERS_LIST;
    try {
      const response = await fetchApiData({ url });
      Object.keys(response.data).forEach((item: any) => {
        setValue(item, response.data[item]);
      });
      setTiers(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getTierList();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      if (data.id) {
        let body = {
          title: data?.title,
          title_url: data?.title_url,
          sort_id: data?.sort_id,
        };
        let res = await updateData({
          url: `${UPDATE_TIERS_LIST}/${data.id}`,
          body,
        });
        if (res) {
          reset();
          getTierList();
          dispatch(alertSuccess("Tier updated successfully"));
        }
      } else {
        let body = {
          title: data?.title,
          title_url: data?.title_url,
          sort_id: tiers.length + 1,
        };
        const response = await saveApiData({ url: SAVE_TIERS_LIST, body });
        getTierList();
        dispatch(alertSuccess("Tier created successfully"));
        reset();
      }
    } catch (error: any) {
      // Handle errors
      console.error("API Error:", error.message);
      dispatch(alertFailure(error.message));
    }
  };

  function handleEdit(data: any) {
    setEditedData(data)
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    reset();
    Object.keys(data).forEach((item: any) => {
      setValue(item, data[item]);
    });
  }

  useEffect(() => {
    if(editedData) {
      Object.keys(editedData).forEach((item: any) => {
        setValue(item, editedData[item]);
      });
    }
  }, [editedData])

  async function handleDelete() {
    try {
      const url = `${DELETE_TIERS_LIST}/${deleteTierId}`;
      const res = await deleteApiData({ url });
      if (res) {
        dispatch(alertSuccess("Tier deleted successfully"));
        getTierList();
      }
    } catch (err: any) {
      dispatch(
        alertFailure(`Error: ${err?.message || "Unable to delete Tier"}`)
      );
    }
    setDeleteModalOpen(false);
  }

  const updateSorting = async (updatedData: any) => {
    try {
      const response = await updateApiData({
        url: SORT_TIERS_LIST,
        body: {
          tier_section: updatedData,
        },
      });
      if (response.status === 200) {
        dispatch(alertSuccess("Updated Successfully"));
      } else {
        dispatch(alertFailure(response?.message));
      }
    } catch (error: any) {
      console.error(`${error?.message || "Error Updating"}`);
      dispatch(alertFailure(`${error?.message || "Error Updating"}`));
    }
  };

  const onDragEnd = (result: any) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items: Tier[] = Array.from(tiers);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      id: item?.id,
      sort_id: index + 1,
    }));
    updateSorting(data);
    setTiers(items);
  };

  return (
    <div>
      <div>
        <Row>
          <Col xs={12}>
            <CardBody className="p-0">
              <form onSubmit={handleSubmit(onSubmit)}>
              <input type="text" hidden name="id" ref={register()} />
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-firstname-input"
                        className="form-label"
                      >
                        List Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter List Title"
                        name="title"
                        ref={register({
                          required: "This Field is Required",
                        })}
                      />
                      <FormValidationError
                        errorMessage={errors?.title?.message}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-firstname-input"
                        className="form-label"
                      >
                        List URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter List URL"
                        name="title_url"
                        ref={register({
                          required: "This Field is Required",
                        })}
                      />
                      <FormValidationError
                        errorMessage={errors?.title_url?.message}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button type="submit" className="btn btn-info w-md">
                    Submit
                  </button>
                </div>
              </form>
            </CardBody>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <div>
              <Label className="form-label">Tiers Table</Label>
            </div>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>List Title</th>
                        <th>List URL</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <tbody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {tiers?.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.title}</td>
                                    <td>{item.title_url}</td>
                                    <td>
                                      <span className="d-flex">
                                        <button
                                          className="btn btn-info btn-sm me-2"
                                          onClick={() => handleEdit(item)}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => {
                                            setDeleteModalOpen(true);
                                            setDeleteTierId(item?.id);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <DeleteModal
        title="Tier"
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        onDeleteClick={handleDelete}
      />
    </div>
  );
};

export default Tiers;
