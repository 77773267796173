import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import ImageUploaderWithForm from "../../components/Base/ImageUploader/ImageUploaderWithForm";
import { CardBody, Table, Card, Row, Col } from "reactstrap";
import DeleteModal from "../../components/Base/DeleteModal";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { updateFeaturePageSorting } from "../../request/faq";
import * as apiHelper from "../../request/apiHelper";
import {
  DELETE_FEATURE_PAGE,
  GET_FEATURE_PAGE,
  SAVE_FEATURE_PAGE,
  SORT_FEATURE_PAGE,
  UPDATE_FEATURE_PAGE,
} from "../../request/config/Urls";
import { deleteApiData, fetchApiData } from "../../request/config/index";
import FormValidationError from "../../components/Base/FormValidationError";
import { Button } from "@material-ui/core";

interface featureSection {
  id: number;
  title: string;
  learn_more_url: string;
  background_color: string;
  description: string;
  img_url: string;
  sort_id: number | null;
  created_at: string;
  updated_at: string;
}

const FeatureSection = () => {
  const [image, setImage]: any = useState("");
  const methods = useForm();
  const [featureSectionsData, setFeatureSectionsData]: any = useState<
    featureSection[]
  >([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteFeatureSectionId, setDeleteFeatureSectionId] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetchApiData({ url: GET_FEATURE_PAGE });
      setFeatureSectionsData(response.data);
    } catch (error: any) {
      console.error(
        `${error?.response?.data?.message || "Error fetching data:"}`
      );
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data:"}`
        )
      );
    }
  };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  const formData = useRef(new FormData());

  const onSubmit = async (event: any) => {
    console.log(event);

    if (typeof image != "string") {
      event.image = image;
    }
    for (const key in event) {
      if (event[key] === "image" && typeof event.image != "string") {
        formData.current.set(key, event[key]);
      } else if (event[key] !== "image") {
        formData.current.set(key, event[key]);
      }
    }
    try {
      if (event.id) {
        let res = await apiHelper.postFile(
          `${UPDATE_FEATURE_PAGE}/${event.id}`,
          formData.current
        );
        dispatch(alertSuccess("Feature updated successfully"));
        if (res) {
          reset();
          setImage(null);
          fetchData();
        }
      } else {
        formData.current.set(
          "sort_id",
          (featureSectionsData.length + 1).toString()
        );
        let res = await apiHelper.postFile(SAVE_FEATURE_PAGE, formData.current);
        dispatch(alertSuccess("Feature added successfully"));
        if (res) {
          reset();
          setImage(null);
          fetchData();
        }
      }
    } catch (error: any) {
      dispatch(
        alertFailure(`Error: ${error?.message || "Unable to submit data"}`)
      );
    }
  };
  const handleFileSelect = (file: any) => {
    setImage(file);
  };

  async function handleDelete() {
    try {
      const url = `${DELETE_FEATURE_PAGE}/${deleteFeatureSectionId}`;
      const res = await deleteApiData({ url });
      if (res) {
        dispatch(alertSuccess("Feature deleted successfully"));
        fetchData();
      }
    } catch (err: any) {
      dispatch(
        alertFailure(`Error: ${err?.message || "Unable to delete feature"}`)
      );
    }
    setDeleteModalOpen(false);
  }

  function handleEdit(data: any) {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setImage(data.img_url);
    Object.keys(data).forEach((item: any) => {
      if (item !== "image") {
        setValue(item, data[item]);
      }
    });
    setImage(data.img_url);
  }

  const updateSorting = async (updatedData: any) => {
    try {
      const response = await updateFeaturePageSorting(SORT_FEATURE_PAGE, {
        feature_section: updatedData,
      });
      if (response.status === 200) {
        dispatch(alertSuccess("Updated Successfully"));
      } else {
        dispatch(alertFailure(response?.message));
      }
    } catch (error: any) {
      console.error(`${error?.message || "Error Updating"}`);
      dispatch(alertFailure(`${error?.message || "Error Updating"}`));
    }
  };

  const onDragEnd = (result: any) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items: featureSection[] = Array.from(featureSectionsData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      id: item?.id,
      sort_id: index + 1,
    }));
    updateSorting(data);
    setFeatureSectionsData(items);
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <CardBody className="p-0">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <input type="text" hidden name="id" ref={register()} />
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-firstname-input"
                        className="form-label"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input"
                        placeholder="Enter Title"
                        name="title"
                        ref={register({
                          required: "Title is Required",
                        })}
                      />
                      <FormValidationError
                        errorMessage={errors?.title?.message}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-lastname-input"
                        className="form-label"
                      >
                        Learn More URL
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="basicpill-lastname-input"
                        placeholder="Enter URL"
                        name="learn_more_url"
                        ref={register({
                          required: "This Field is Required",
                        })}
                      />
                      <FormValidationError
                        errorMessage={errors?.learn_more_url?.message}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="example-text-input" className="form-Label">
                    Background Color
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="example-text-input"
                    name="background_color"
                    placeholder="Enter Color linear-gradient(180deg, rgba(147, 19, 51, 0.69) 0%, #060B26 50%)"
                    ref={register({
                      required: "This Field is Required",
                    })}
                  />
                  <FormValidationError
                    errorMessage={errors?.background_color?.message}
                  />
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-address-input"
                        className="form-label"
                      >
                        Description
                      </label>
                      <textarea
                        id="basicpill-address-input"
                        className="form-control"
                        placeholder="Enter Description"
                        rows={2}
                        name="description"
                        ref={register({
                          required: "This Field is Required",
                        })}
                      ></textarea>
                      <FormValidationError
                        errorMessage={errors?.description?.message}
                      />
                    </div>
                  </div>
                </div>
                <Row>
                  <label className="form-label">Feature Background</label>
                  <Col className="col-12">
                    <ImageUploaderWithForm
                      field="image"
                      onFileSelect={handleFileSelect}
                      initialSrc={image}
                    />
                  </Col>
                </Row>
                <div>
                  <button type="submit" className="btn btn-info w-md mt-2">
                    Submit
                  </button>
                </div>
              </form>
            </FormProvider>
          </CardBody>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={12}>
          <div>
            <label className="form-label">Feature Table</label>
          </div>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Icon</th>
                      <th>Title</th>
                      <th>Learn More URL</th>
                      <th>Background Color</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {featureSectionsData?.map((item: any, index: any) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <th scope="row">{item?.id}</th>
                                  <td>
                                    <img
                                      width={30}
                                      height={30}
                                      src={item?.img_url}
                                      alt=""
                                    />
                                  </td>
                                  <td>{item?.title}</td>
                                  <td>{item?.learn_more_url}</td>
                                  <td>{item?.background_color}</td>
                                  <td title={item.description}>
                                    {item?.description?.length > 20
                                      ? item?.description.slice(0, 20) + "..."
                                      : item?.description}
                                  </td>
                                  <td>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      style={{ marginRight: "10px" }}
                                      onClick={() => handleEdit(item)}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        setDeleteModalOpen(true);
                                        setDeleteFeatureSectionId(item?.id);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        title="Feature Section"
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        onDeleteClick={handleDelete}
      />
    </div>
  );
};

export default FeatureSection;
