import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardBody, Col, Row } from "reactstrap";
import DeleteModal from "../../components/Base/DeleteModal";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import {
  DELETE_STAKING_COMPONENT,
  GET_STAKING_COMPONENT,
  SAVE_STAKING_COMPONENT,
  UPDATE_STAKING_COMPONENT,
} from "../../request/config/Urls";
import {
  deleteApiData,
  fetchApiData,
  saveApiData,
  updateData,
} from "../../request/config/index";
import FormValidationError from "../../components/Base/FormValidationError";

const StakingInfoComponent = () => {
  const [stakingInfo, setStakingInfo] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [deleteStckInfoId, setDeleteStackInfoId] = useState(0);
  const dispatch = useDispatch();

  const getStakingInfoList = async () => {
    const url = GET_STAKING_COMPONENT;
    try {
      const response = await fetchApiData({ url });
      reset(response?.data[0]);
      setStakingInfo(response.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getStakingInfoList();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      if (data.id) {
        const url = UPDATE_STAKING_COMPONENT + "/" + data.id;
        let res = await updateData({ url, body: data });
        if (res) {
          reset();
          getStakingInfoList();
          dispatch(alertSuccess("Info updated successfully"));
        }
      } else {
        alert("asd");
        await saveApiData({
          url: SAVE_STAKING_COMPONENT,
          body: data,
        });
        getStakingInfoList();
        dispatch(alertSuccess("Info created successfully"));
      }
    } catch (error: any) {
      console.error("API Error:", error.message);
      dispatch(alertFailure(error.message));
    }
  };

  function handleEdit(data: any) {
    if (typeof window !== "undefined") {
      // Scroll to the top of the window
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    // console.log(register("background_color"));
    reset();
    Object.keys(data).forEach((item: any) => {
      setValue(item, data[item]);
    });
  }

  async function handleDelete() {
    try {
      const url = DELETE_STAKING_COMPONENT + "/" + deleteStckInfoId;
      const res = await deleteApiData({ url });
      if (res) {
        dispatch(alertSuccess("Info deleted successfully"));
        getStakingInfoList();
      }
    } catch (err: any) {
      dispatch(
        alertFailure(`Error: ${err?.message || "Unable to delete Info"}`)
      );
    }
    setDeleteModalOpen(false);
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <CardBody className="p-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-lg-12">
                <div className="mb-3">
                  <label
                    htmlFor="basicpill-firstname-input"
                    className="form-label"
                  >
                    Staking Info Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input"
                    placeholder="Enter Info"
                    name="title"
                    ref={register({
                      required: "This Field is Required",
                    })}
                  />
                  <FormValidationError errorMessage={errors?.title?.message} />
                </div>
              </div>
              <div className="col-lg-12 pe-0">
                <div className="mb-3">
                  <label
                    htmlFor="basicpill-address-input"
                    className="form-label"
                  >
                    Staking Description
                  </label>
                  <textarea
                    id="basicpill-address-input"
                    className="form-control"
                    placeholder="Enter Description"
                    rows={2}
                    name="description"
                    ref={register({
                      required: true,
                    })}
                  ></textarea>
                </div>
              </div>
              <div>
                <button type="submit" className="btn btn-info w-md">
                  Submit
                </button>
              </div>
            </form>
          </CardBody>
        </Col>
      </Row>
      <DeleteModal
        title="Staking Info"
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        onDeleteClick={handleDelete}
      />
    </div>
  );
};

export default StakingInfoComponent;
