import React, { useEffect, useRef, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import ImageUploaderWithForm from "../../components/Base/ImageUploader/ImageUploaderWithForm";
import DeleteModal from "../../components/Base/DeleteModal";
import { updateRegisterPageSorting } from "../../request/faq";
import * as apiHelper from "../../request/apiHelper";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { deleteApiData, fetchApiData } from "../../request/config/index";
import {
  DELETE_REGISTER_PAGE,
  GET_REGISTER_PAGE,
  SAVE_REGISTER_PAGE,
  SORT_REGISTER_PAGE,
  UPDATE_REGISTER_PAGE,
} from "../../request/config/Urls";
import { Button } from "@material-ui/core";

interface registerSection {
  id: number;
  title: string;
  button_name: string;
  learn_more_url: string;
  description: string;
  img_url: string;
  sort_id: number | null;
  created_at: string;
  updated_at: string;
}

const RegisterSection = () => {
  const [image, setImage]: any = useState("");
  const methods = useForm();
  const [registerSectionsData, setRegisterSectionsData] = useState<
    registerSection[]
  >([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteRegisterSectionId, setDeleteRegisterSectionId] = useState(0);
  const [editedData, setEditedData] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetchApiData({ url: GET_REGISTER_PAGE });
      setRegisterSectionsData(response.data);
    } catch (error: any) {
      console.error(
        `${error?.response?.data?.message || "Error fetching data:"}`
      );
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data:"}`
        )
      );
    }
  };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = methods;
  const formData = useRef(new FormData());

  const onSubmit = async (event: any) => {
    console.log("🚀 ~ onSubmit ~ event:", event);
    if (typeof image != "string") {
      event.image = image;
    }
    for (const key in event) {
      if (event[key] === "image" && typeof event.image != "string") {
        formData.current.set(key, event[key]);
      } else if (event[key] !== "image") {
        formData.current.set(key, event[key]);
      }
    }
    try {
      if (event.id) {
        let res = await apiHelper.postFile(
          `${UPDATE_REGISTER_PAGE}/${event.id}`,
          formData.current
        );
        dispatch(alertSuccess("Register updated successfully"));
        if (res) {
          reset();
          setImage(null);
          fetchData();
        }
      } else {
        formData.current.set(
          "sort_id",
          (registerSectionsData.length + 1).toString()
        );
        let res = await apiHelper.postFile(
          SAVE_REGISTER_PAGE,
          formData.current
        );
        dispatch(alertSuccess("Register added successfully"));
        if (res) {
          reset();
          setImage(null);
          fetchData();
        }
      }
    } catch (error: any) {
      dispatch(alertFailure("error:" + error));
    }
  };
  const handleFileSelect = (file: any) => {
    console.log("field", file);
    setImage(file);
  };

  async function handleDelete(id: any) {
    try {
      const url = `${DELETE_REGISTER_PAGE}/${deleteRegisterSectionId}`;
      const res = await deleteApiData({ url });
      if (res) {
        dispatch(alertSuccess("Register deleted successfully"));
        fetchData();
      }
    } catch (err: any) {
      dispatch(
        alertFailure(`Error: ${err?.message || "Unable to delete register"}`)
      );
    }
    setDeleteModalOpen(false);
  }

  function handleEdit(data: any) {
    setEditedData(data);
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setImage(null);
    Object.keys(data).forEach((item: any) => {
      if (item !== "image") {
        setValue(item, data[item]);
      }
    });
    setImage(data.img_url);
  }

  const updateSorting = async (updatedData: any) => {
    try {
      const response = await updateRegisterPageSorting(SORT_REGISTER_PAGE, {
        register_section: updatedData,
      });
      if (response.status === 200) {
        dispatch(alertSuccess("Updated Successfully"));
      } else {
        dispatch(alertFailure(response?.message));
      }
    } catch (error: any) {
      console.error(`${error?.message || "Error Updating"}`);
      dispatch(alertFailure(`${error?.message || "Error Updating"}`));
    }
  };

  const onDragEnd = (result: any) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items = Array.from(registerSectionsData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      id: item?.id,
      sort_id: index + 1,
    }));
    updateSorting(data);
    setRegisterSectionsData(items);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="text" hidden name="id" ref={register()} />
          <div className="mb-3">
            <label htmlFor="landing-title-input" className="form-label">
              Landing Title
            </label>
            <input
              type="text"
              className={`form-control ${errors.title ? "is-invalid" : ""}`}
              ref={register({
                required: "Title is required",
              })}
              name="title"
              placeholder="Enter Title"
            />
            {errors.title && (
              <div className="invalid-feedback">
                {errors.title.message.toString()}
              </div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="powered-by-input" className="form-label">
              Button Name
            </label>
            <input
              type="text"
              className={`form-control ${
                errors.button_name ? "is-invalid" : ""
              }`}
              id="powered-by-input"
              name="button_name"
              placeholder="Enter Powered By"
              ref={register({
                required: "Button name is required",
              })}
            />
            {errors.button_name && (
              <div className="invalid-feedback">
                {errors.button_name.message.toString()}
              </div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="powered-by-input" className="form-label">
              Button Url
            </label>
            <input
              type="text"
              className={`form-control ${
                errors.learn_more_url ? "is-invalid" : ""
              }`}
              id="button-url-input"
              name="learn_more_url"
              placeholder="Button Url"
              ref={register({
                required: "Button URL is required",
              })}
            />
            {errors.learn_more_url && (
              <div className="invalid-feedback">
                {errors.learn_more_url.message.toString()}
              </div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="description-input" className="form-label">
              Description
            </label>
            <textarea
              id="description-input"
              className={`form-control ${
                errors.description ? "is-invalid" : ""
              }`}
              placeholder="Enter Description"
              name="description"
              rows={2}
              ref={register({
                required: "Description is required",
              })}
            ></textarea>
            {errors.description && (
              <div className="invalid-feedback">
                {errors.description.message.toString()}
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <label className="form-label">Landing Background</label>
              <ImageUploaderWithForm
                field="image"
                onFileSelect={handleFileSelect}
                initialSrc={image}
              />
            </div>
          </div>

          <button type="submit" className="btn btn-info mt-5 w-md">
            Submit
          </button>
        </form>
      </FormProvider>
      <>
        <Row className="mt-4">
          <Col xs={12}>
            <div>
              <Label className="form-label">Register Flow Table</Label>
            </div>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Icon</th>
                        <th>Title</th>
                        <th>Button Text</th>
                        <th>Description</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <tbody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {registerSectionsData?.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      <img
                                        width={30}
                                        height={30}
                                        src={item?.img_url}
                                        alt=""
                                      />
                                    </td>
                                    <td>{item?.title}</td>
                                    <td>{item?.button_name}</td>
                                    <td title={item.description}>
                                      {item?.description?.length > 20
                                        ? item?.description.slice(0, 20) + "..."
                                        : item?.description}
                                    </td>
                                    <td>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => handleEdit(item)}
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                          setDeleteModalOpen(true);
                                          setDeleteRegisterSectionId(item?.id);
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
      <DeleteModal
        title="Register Section"
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        onDeleteClick={handleDelete}
      />
    </div>
  );
};

export default RegisterSection;
