import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    navTabsCustom: {
        borderBottom: "1px solid white",
        "& .nav-item": {
            position: "relative",
            color: "black",
    
            "& .nav-link": {
                border: "none",
    
                "&::after" : {
                    content: "",
                    background: "black",
                    height: "1px",
                    position: "absolute",
                    width: "100%",
                    left: 0,
                    bottom: "-1px",
                    transition: "all 250ms ease 0s",
                    transform: "scale(0)",
                },
    
                "& .active": {
                    color: "red",
    
                    "&:after" : {
                        transform: "scale(1)"
                    }
                }
    
            }
        }
    }
}));

export default useStyles;
