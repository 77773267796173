import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ImageUploader from "../../components/Base/ImageUploader";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import * as apiHelper from "../../request/apiHelper";
import { fetchApiData } from "../../request/config/index";
import { GET_LANDING_PAGE, SAVE_LANDING_PAGE } from "../../request/config/Urls";
import FormValidationError from "../../components/Base/FormValidationError";

const LandingComponent = () => {
  const { register, handleSubmit, setValue, getValues, errors } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const formData = useRef(new FormData());

  const [landingImage, setLandingImage] = useState(null);
  const [landingImageSmall, setLandingImageSmall] = useState(null);
  const [landingImageMobile, setLandingImageMobile] = useState(null);
  const [headerLogo, setHeaderLogo] = useState(null);
  const [poweredLogo, setPoweredLogo] = useState(null);
  const [stakingLogo, setStakingLogo] = useState(null);
  const [notificationLogo, setNotificationLogo] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetchApiData({ url: GET_LANDING_PAGE });
      const {
        landing_title,
        powered_by,
        landing_description,
        footer_copyright,
        footer_description,
        attachments,
      } = response.data[0];

      setValue("title", landing_title);
      setValue("powered_by", powered_by);
      setValue("description", landing_description);
      setValue("footer_copyright", footer_copyright);
      setValue("footer_description", footer_description);

      attachments.forEach((attachment: any) => {
        const field = attachment?.pivot?.attachment_purpose;
        if (field) {
          setValue(field, attachment?.file_url);
        }
      });
    } catch (error: any) {
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data:"}`
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      if (landingImage) {
        data.landing_background = landingImage;
      }
      if (landingImageSmall) {
        data.landing_backgroundSM = landingImageSmall;
      }
      if (landingImageMobile) {
        data.landing_background_mobile = landingImageMobile;
      }
      if (headerLogo) {
        data.header_logo = headerLogo;
      }
      if (poweredLogo) {
        data.poweredBy_logo = poweredLogo;
      }
      if (stakingLogo) {
        data.staking_icon = stakingLogo;
      }
      if (notificationLogo) {
        data.notified_icon = notificationLogo;
      }

      for (const key in data) {
        if (data[key] === "image" && typeof data.image != "string") {
          formData.current.set(key, data[key]);
        } else if (data[key] !== "image") {
          formData.current.set(key, data[key]);
        }
      }

      const response = await apiHelper.postFile(
        SAVE_LANDING_PAGE,
        formData.current
      );
      dispatch(alertSuccess("Saved Successfully"));
      if (response.status !== 200) {
        dispatch(alertFailure(response.message));
      }
      setLandingImage(null);
      setNotificationLogo(null);
      setStakingLogo(null);
      setPoweredLogo(null);
      setHeaderLogo(null);
      setLandingImageMobile(null);
      setLandingImageSmall(null);
      fetchData();
    } catch (error: any) {
      console.log(error);
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error posting data"}`
        )
      );
    }
  };

  const handleLandingImageSelect = (field: any) => (file: any) => {
    setLandingImage(file);
  };

  const handleLandingImageSmallSelect = (field: any) => (file: any) => {
    setLandingImageSmall(file);
  };

  const handleLandingImageMobileSelect = (field: any) => (file: any) => {
    setLandingImageMobile(file);
  };

  const handleHeaderImageSelect = (field: any) => (file: any) => {
    setHeaderLogo(file);
  };

  const handlePoweredLogoSelect = (field: any) => (file: any) => {
    setPoweredLogo(file);
  };

  const handleStakingLogoSelect = (field: any) => (file: any) => {
    setStakingLogo(file);
  };

  const handleNotificationLogoSelect = (field: any) => (file: any) => {
    setNotificationLogo(file);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Landing Title
          </label>
          <input
            type="text"
            name="title"
            className="form-control"
            placeholder="Enter Title"
            ref={register({
              required: "This Field is Required",
            })}
          />
          <FormValidationError errorMessage={errors?.title?.message} />
        </div>

        <div className="mb-3">
          <label htmlFor="poweredBy" className="form-label">
            Powered By
          </label>
          <input
            type="text"
            name="powered_by"
            className="form-control"
            placeholder="Enter Powered By"
            ref={register({
              required: "This Field is Required",
            })}
          />
          <FormValidationError errorMessage={errors?.powered_by?.message} />
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Landing Description
          </label>
          <textarea
            className="form-control"
            name="description"
            placeholder="Enter Description"
            rows={4}
            ref={register({
              required: "This Field is Required",
            })}
          ></textarea>
          <FormValidationError errorMessage={errors?.description?.message} />
        </div>

        <div className="mb-3">
          <label htmlFor="footer_copyright" className="form-label">
            Footer Copyright
          </label>
          <input
            type="text"
            className="form-control"
            name="footer_copyright"
            ref={register({
              required: "This Field is Required",
            })}
            placeholder="@Copyright"
          />
          <FormValidationError
            errorMessage={errors?.footer_copyright?.message}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="footer_description" className="form-label">
            Footer Description
          </label>
          <textarea
            className="form-control"
            name="footer_description"
            placeholder="Enter Footer Description"
            rows={4}
            ref={register({
              required: "This Field is required",
            })}
          ></textarea>
          <FormValidationError
            errorMessage={errors?.footer_description?.message}
          />
        </div>

        <div className="row">
          <h5>
            <b>Landing Images</b>
          </h5>
          <div className="col-lg-12 mt-3">
            <label htmlFor="" className="form-label">
              Landing Background
            </label>
            <input
              type="text"
              name="landing_background"
              hidden
              ref={register({})}
              id=""
            />
            <ImageUploader
              onFileSelect={handleLandingImageSelect("landing_background")}
              initialSrc={getValues("landing_background")}
              width={"100%"}
              height={"600px"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mt-3">
            <label htmlFor="" className="form-label">
              Landing Background Small
            </label>
            <input
              type="text"
              name="landing_backgroundSM"
              hidden
              ref={register({})}
              id=""
            />
            <ImageUploader
              onFileSelect={handleLandingImageSmallSelect(
                "landing_backgroundSM"
              )}
              initialSrc={getValues("landing_backgroundSM")}
              width={"100%"}
              height={"235px"}
            />
          </div>

          <div className="col-lg-6 mt-3">
            <label htmlFor="" className="form-label">
              Landing Background Mobile
            </label>
            <input
              type="text"
              name="landing_background_mobile"
              hidden
              ref={register({})}
              id=""
            />
            <ImageUploader
              onFileSelect={handleLandingImageMobileSelect(
                "landing_background_mobile"
              )}
              initialSrc={getValues("landing_background_mobile")}
              width={"100%"}
              height={"235px"}
            />
          </div>
        </div>

        <div className="row mt-3 ml-1">
          <span>
            <b>Header Images</b>
          </span>
        </div>
        <div className="row mt-3">
          <div className="col-lg-6 mt-3">
            <label htmlFor="" className="form-label">
              Header Logo
            </label>
            <input
              type="text"
              name="header_logo"
              hidden
              ref={register({})}
              id=""
            />
            <ImageUploader
              onFileSelect={handleHeaderImageSelect("header_logo")}
              initialSrc={getValues("header_logo")}
              width={"100%"}
              height={"100px"}
            />
          </div>

          <div className="col-lg-6 mt-3">
            <label htmlFor="" className="form-label">
              Powered By Logo
            </label>
            <input
              type="text"
              name="poweredBy_logo"
              hidden
              ref={register({})}
              id=""
            />
            <ImageUploader
              onFileSelect={handlePoweredLogoSelect("poweredBy_logo")}
              initialSrc={getValues("poweredBy_logo")}
              width={"100%"}
              height={"100px"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mt-3">
            <label htmlFor="" className="form-label">
              Staking Icon
            </label>
            <input
              type="text"
              name="staking_icon"
              hidden
              ref={register({})}
              id=""
            />
            <ImageUploader
              onFileSelect={handleStakingLogoSelect("staking_icon")}
              initialSrc={getValues("staking_icon")}
              width={"100%"}
              height={"100px"}
            />
          </div>

          <div className="col-lg-6 mt-3">
            <label htmlFor="" className="form-label">
              Notified Icon
            </label>
            <input
              type="text"
              name="notified_icon"
              hidden
              ref={register({})}
              id=""
            />
            <ImageUploader
              onFileSelect={handleNotificationLogoSelect("notified_icon")}
              initialSrc={getValues("notified_icon")}
              width={"100%"}
              height={"100px"}
            />
          </div>
        </div>

        <button type="submit" className="btn btn-info mt-5 w-md">
          Submit
        </button>
      </form>
    </>
  );
};

export default LandingComponent;
