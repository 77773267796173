import axios from "axios";
// Token validity check function

const isTokenValid = () => {
  const token = localStorage.getItem("access_token");
  if (!token) {
    return false;
  }
  
  const tokenPayload = JSON.parse(atob(token.split('.')[1]));
  const isExpired = Date.now() > tokenPayload.exp * 1000;
  
  return !isExpired;
};

// Function to handle logout
export const handleLogout = () => {
  localStorage.clear(); // Clear local storage
  window.location.href = "/Login";
};

// Apply base url for axios
const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;
const signature = process.env.REACT_APP_MESSAGE_SIGNATURE;

const axiosApi = axios.create({
  baseURL: API_URL,
});

// Set common headers
axiosApi.defaults.headers.common["msgSignature"] = signature;

// Request interceptor to check token validity
axiosApi.interceptors.request.use(
  (request : any) => {
    
    // Check if the request is for the login API
    if (request.url.endsWith("/login")) {
      return request; // Bypass token check for login
    }
    
    if (!isTokenValid()) {
      handleLogout();
      throw new axios.Cancel("Token invalid or expired");
    }
    
    // Set the Authorization header with the current token
    const token = localStorage.getItem("access_token");
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    
    return request;
  },
  (error) => {
    
    return Promise.reject(error)
  }
);

// Response interceptor to handle specific error cases
axiosApi.interceptors.response.use(
  (response) => {
    // Check if the response indicates an expired token
    
    if (response.data && response.data.status === 401) {
      const errorMessage = response.data.message;
      const errorCode = response.data.msgCode;
      
      if (errorMessage === "Token Expired" || errorCode === "JWT_101") {
        handleLogout();
        // Optionally, you can reject the promise here to stop further processing
        return Promise.reject(new Error("Token expired"));
      }
    }
    return response;
  },
  (error) => {
    // Handle HTTP error responses
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      handleLogout();
    }
    
    return Promise.reject(error);
  }
);

export async function get(url: string, config = {}) {
  return await axiosApi
    .get(url, {...config})
    .then((response) => response.data);
}

export async function post(url: string, data: any, config = {}) {
  return axiosApi
    .post(url, {...data}, {...config})
    .then((response) => response.data);
}

export async function postFile(url: string, data: any) {
  return axiosApi.post(url, data).then((response) => response.data);
}

export async function putFile(url: string, data: any) {
  return axiosApi.put(url, data).then((response) => response.data);
}

export async function put(url: string, data: any, config = {}) {
  return axiosApi
    .put(url, {...data}, {...config})
    .then((response) => response.data);
}

export async function patch(url: string, data: any, config = {}) {
  return axiosApi
    .patch(url, {...data}, {...config})
    .then((response) => response.data);
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, {...config})
    .then((response) => response.data);
}

export async function postDownload(url: string, data: object) {
  // const res = await fetch(this.buildUrl(url), {
  //     method: "POST",
  //     headers: this.getHeader()
  // })
  // return res.blob()
  // return axiosApi
  //     .post(url, { ...data })
  //     .then(response => response.blob())
}