"use client";
import React from "react";
import Breadcrumb from "../../components/Base/BreadCrumbs";
import QuestionCategory from "./QuestionCategory";
import DefaultLayout from "../../components/Layout/DefaultLayout";

const Faqs = () => {
  return (
    <DefaultLayout>
      <div className="page-content">
        <Breadcrumb title="FAQS" breadcrumbItem="FAQS" />
        <QuestionCategory />
      </div>
    </DefaultLayout>
  );
};

export default Faqs;
