import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardBody, Col, Row } from "reactstrap";
import FormEditor from "../../components/Base/FormEditor";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import {
  GET_GOOGLE_FORM_PAGE,
  SAVE_GOOGLE_FORM_PAGE,
} from "../../request/config/Urls";
import { fetchApiData, saveApiData } from "../../request/config/index";

const FormInput = ({ id, label, register, requiredMessage, errors }: any) => 
  {
    console.log(id, "kjasdkj")
    return(
  <div className="col-lg-6 mb-3">
    <label htmlFor={id} className="form-label">
      {label}
    </label>
    <input
     ref={register({
      required: requiredMessage,
    })}
      type="text"
      className={`form-control ${errors[id] ? "is-invalid" : ""}`}
      id={id}
      placeholder={`Enter ${label}`}
      name={id}
    />
    {errors[id] && <div className="invalid-feedback">{errors[id].message}</div>}
  </div>
)};

const GoogleFormSection = () => {
  const [editorData, setEditorData] = useState("");
  const [data, setData] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const { data } = await fetchApiData({ url: GET_GOOGLE_FORM_PAGE });
      reset(data[0]);
      Object.keys(data[0]).forEach((item: any) => {
          setValue(item, data[item]);
      });
      setEditorData(data[0].description);
      setData(data[0]);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message || "Something went wrong";
      dispatch(alertFailure(errorMsg));
      console.error("Fetch Error:", error);
    }
  };

  useEffect(() => {
    if(data) {
      Object.keys(data).forEach((item: any) => {
        setValue(item, data[item]);
    });
    }
  }, [data])

  const onSubmit = async (formData: any) => {
    const payload = { ...formData, description: editorData };
    try {
      const { status, message } = await saveApiData({
        url: SAVE_GOOGLE_FORM_PAGE,
        body: payload,
      });
      status !== 200
        ? dispatch(alertFailure(message))
        : dispatch(alertSuccess("Saved Successfully"));
      fetchData();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message || "Something went wrong";
      dispatch(alertFailure(errorMsg));
      console.error("Submit Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12}>
            <CardBody className="p-0">
              <div className="row">
                <FormInput
                  id="title"
                  label="Heading"
                  register={register}
                  requiredMessage="Title is required"
                  errors={errors}
                />
                <FormInput
                  id="button_title"
                  label="Button Title"
                  register={register}
                  requiredMessage="Button title is required"
                  errors={errors}
                />
              </div>

              <div className="row">
                <FormInput
                  id="link"
                  label="Link"
                  register={register}
                  requiredMessage="Link is required"
                  errors={errors}
                />
              </div>

              <Row>
                <Col lg={12}>
                  <h4 className="card-title">Description</h4>
                  <FormEditor data={editorData} setData={setEditorData} />
                </Col>
              </Row>

              <div>
                <button type="submit" className="btn btn-info w-md mt-2">
                  Submit
                </button>
              </div>
            </CardBody>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default GoogleFormSection;
