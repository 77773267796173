import React, { useEffect, useState } from "react";
import { CardBody } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const FormEditor = ({ data, setData }: any) => {
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    import("tinymce/tinymce");
    setEditorLoaded(true);
  }, []);

  const handleImageUpload = (blobInfo: any, progress: any, failure: any) => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("access_token");
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/admin/pool/upload-file?id=` + Date.now(),
        true
      );
      xhr.setRequestHeader("Authorization", "Bearer " + token);
      console.log(xhr, "xhrrrr");

      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
        if (progress && typeof progress === "function") {
          const percent = 0;
          progress(percent);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 403) {
          reject({ message: "HTTP Error: " + xhr.status, remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);

        if (!json || typeof json.data.Location != "string") {
          reject("Invalid JSON: " + xhr.responseText);
          return;
        }
        resolve(json.data.Location);
      };

      xhr.onerror = () => {
        reject({ message: "Image upload failed", remove: true });
        if (failure && typeof failure === "function") {
          failure("Image upload failed");
        }
      };

      xhr.send(formData);
    });
  };

  const editorConfiguration: any = {
    height: 500,
    menubar: true,
    plugins: [
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      "code",
      "help",
      "wordcount",
    ],
    toolbar:
      "undo redo | blocks | " +
      "bold italic forecolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help",
    images_upload_url: `${BASE_URL}/admin/pool/upload-file`,
    automatic_uploads: true,
    images_reuse_filename: true,
    images_upload_handler: handleImageUpload,
  };
  
  return (
    <div>
      <CardBody className="p-0">
        {editorLoaded ? (
          <Editor
            apiKey={process.env.REACT_APP_TINYMCE_KEY}
            value={data}
            init={editorConfiguration}
            onEditorChange={(content, editor) => {
              setData(content);
            }}
          />
        ) : (
          <p>Loading editor...</p>
        )}
      </CardBody>
    </div>
  );
};

export default FormEditor;
