import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";
import { useForm } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import moment from "moment";
import DeleteModal from "../../components/Base/DeleteModal";
import {
  addGuide
} from "../../request/faq";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import {
  deleteApiData,
  fetchApiData,
  updateApiData,
} from "../../request/config/index";
import {
  DELETE_NEED_HELP_GUIDE,
  GET_NEED_HELP_GUIDE,
  SAVE_NEED_HELP_GUIDE,
  SORT_NEED_HELP_GUIDE,
  UPDATE_NEED_HELP_GUIDE,
} from "../../request/config/Urls";
import FormValidationError from "../../components/Base/FormValidationError";

interface Guide {
  id?: number;
  guide_title?: string;
  guide_url?: string;
  created_at?: string;
  updated_at?: string;
  sort_id?: number;
}

const NeedHelpGuide = () => {
  const [guides, setGuides] = useState<Guide[]>([]);
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteGuideId, setDeletedGuideId] = useState<number>(0);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchApiData({ url: GET_NEED_HELP_GUIDE });
      setGuides(response.data);
    } catch (error: any) {
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data"}`
        )
      );
    }
  }, []);

  const saveGuide = async (data: Guide) => {
    try {
      const url = deleteGuideId
        ? `${UPDATE_NEED_HELP_GUIDE}/${deleteGuideId}`
        : SAVE_NEED_HELP_GUIDE;
      const method = deleteGuideId ? "put" : "post";
      const sort_id = deleteGuideId ? data?.sort_id : guides.length + 1;
      await addGuide(
        url,
        {
          guide_title: data?.guide_title,
          guide_url: data?.guide_url,
          sort_id: sort_id,
        },
        method
      );
      dispatch(alertSuccess("Guide saved successfully"));
      reset();
      fetchData();
      setDeletedGuideId(0);
    } catch (error: any) {
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error posting data"}`
        )
      );
    }
  };

  const editGuide = (guide: any) => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setValue("guide_title", guide?.guide_title);
    setValue("guide_url", guide?.guide_url);
    setDeletedGuideId(guide?.id);
  };

  const deleteGuide = async () => {
    try {
      const url = `${DELETE_NEED_HELP_GUIDE}/${deleteGuideId}`;
      await deleteApiData({ url });
      dispatch(alertSuccess("Guide deleted"));
      fetchData();
      setDeletedGuideId(0);
    } catch (error: any) {
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error deleting data"}`
        )
      );
    }
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateSorting = async (updatedData: any) => {
    try {
      const url = SORT_NEED_HELP_GUIDE;
      const response = await updateApiData({
        url,
        body: { guide_section: updatedData },
      });
      if (response.status === 200) {
        dispatch(alertSuccess("Updated Successfully"));
      } else {
        dispatch(alertFailure(response?.message));
      }
    } catch (error: any) {
      console.error(`${error?.message || "Error Updating"}`);
      dispatch(alertFailure(`${error?.message || "Error Updating"}`));
    }
  };

  const onDragEnd = (result: any) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items: Guide[] = Array.from(guides);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      id: item?.id,
      sort_id: index + 1, 
    }));
    updateSorting(data);
    setGuides(items);
  };

  return (
    <div>
      <Row>
        <Col lg={12}>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Title Text</label>
                <input
                  type="text"
                  name="guide_title"
                  className="form-control"
                  placeholder="Enter Title"
                  ref={register({
                    required: "This Field is Required",
                  })}
                />
                <FormValidationError errorMessage={errors?.guide_title?.message}/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Guide URL</label>
                <input
                  type="text"
                  name="guide_url"
                  className="form-control"
                  placeholder="Enter URL"
                  ref={register({
                    required: "This Field is Required",
                  })}
                />
                <FormValidationError errorMessage={errors?.guide_url?.message}/>
              </div>
            </div>
          </div>
          <div>
            <Button onClick={handleSubmit(saveGuide)} color="info">
              Save
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <Card>
            <CardBody>
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>URL</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <tbody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {guides?.map((item: any, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <td>{item?.id}</td>
                                <td>{item?.guide_title}</td>
                                <td>{item?.guide_url}</td>
                                <td>
                                  {moment(item.created_at)?.format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(item.updated_at)?.format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  <span className="d-flex">
                                    <Button
                                      className="me-2"
                                      color="info"
                                      size="sm"
                                      onClick={() => editGuide(item)}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      color="danger"
                                      size="sm"
                                      onClick={() => {
                                        setDeletedGuideId(item?.id);
                                        setDeleteModalOpen(true);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </span>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <DeleteModal
        title="Guide"
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        onDeleteClick={deleteGuide}
      />
    </div>
  );
};

export default NeedHelpGuide;
