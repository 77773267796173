import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import ImageUploaderWithForm from "../../components/Base/ImageUploader/ImageUploaderWithForm";
import DeleteModal from "../../components/Base/DeleteModal";
import { useDispatch } from "react-redux";
import * as apiHelper from "../../request/apiHelper";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import {
  DELETE_TIERS_OVERVIEW,
  GET_TIERS_OVERVIEW,
  SAVE_TIERS_OVERVIEW,
  SORT_TIERS_OVERVIEW,
  UPDATE_TIERS_OVERVIEW,
} from "../../request/config/Urls";
import {
  deleteApiData,
  fetchApiData,
  updateApiData,
} from "../../request/config/index";
import FormValidationError from "../../components/Base/FormValidationError";

interface TierOverview {
  id: number;
  title: string;
  icon_url: string;
  sort_id: number | null;
  created_at: string;
  updated_at: string;
}

const TiersOverview = () => {
  const [icon, setIcon]: any = useState("");
  const methods = useForm();
  const [tiersOverviewData, setTiersOverviewData]: any = useState<
    TierOverview[]
  >([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteTierOverviewId, setDeleteTierOverViewId] = useState(0);
  const [editedData, setEditedData] = useState<any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetchApiData({ url: GET_TIERS_OVERVIEW });
      setTiersOverviewData(response?.data);
    } catch (error: any) {
      console.error(
        `${error?.response?.data?.message || "Error fetching data:"}`
      );
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data:"}`
        )
      );
    }
  };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  console.log("🚀 ~ TiersOverview ~ errors:", errors)
  const formData = useRef(new FormData());

  const onSubmit = async (event: any) => {
    const isAddingNewItem = !event.id;

    if (isAddingNewItem && tiersOverviewData.length >= 4) {
      return;
    }

    if (typeof icon != "string") {
      setIcon(null);
      event.icon = icon;
    }
    for (const key in event) {
      if (event[key] === "icon" && typeof event.icon != "string") {
        formData.current.set(key, event[key]);
      } else if (event[key] !== "icon") {
        formData.current.set(key, event[key]);
      }
    }
    try {
      if (event.id) {
        let res = await apiHelper.putFile(
          UPDATE_TIERS_OVERVIEW + "/" + event.id,
          formData.current
        );
        dispatch(alertSuccess("Tiers Overview updated successfully"));
        if (res) {
          reset();
          setIcon(null);
          fetchData();
        }
      } else {
        formData.current.set(
          "sort_id",
          (tiersOverviewData.length + 1).toString()
        );
        let res = await apiHelper.postFile(
          SAVE_TIERS_OVERVIEW,
          formData.current
        );
        dispatch(alertSuccess("Tiers Overview added successfully"));
        if (res) {
          reset();
          setIcon(null);
          fetchData();
        }
      }
    } catch (error: any) {
      dispatch(
        alertFailure(`Error: ${error?.message || "Unable to submit data"}`)
      );
    }
  };
  const handleFileSelect = (file: any) => {
    setIcon(file);
  };

  async function handleDelete() {
    try {
      const url = `${DELETE_TIERS_OVERVIEW}/${deleteTierOverviewId}`;
      const res = await deleteApiData({ url });
      if (res) {
        dispatch(alertSuccess("TierOverview deleted successfully"));
        fetchData();
      }
    } catch (err: any) {
      dispatch(
        alertFailure(
          `Error: ${err?.message || "Unable to delete tieroverview"}`
        )
      );
    }
    setDeleteModalOpen(false);
  }

  function handleEdit(data: any) {
    setEditedData(data);
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    reset();
    setIcon(data.icon_url);
    Object.keys(data).forEach((item: any) => {
      if (item !== "icon") {
        setValue(item, data[item]);
      }
    });
    setIcon(data.icon_url);
  }

  useEffect(() => {
    if(editedData) {
      Object.keys(editedData).forEach((item: any) => {
        if (item !== "icon") {
          setValue(item, editedData[item]);
        }
      });
      setIcon(editedData?.icon_url);
    }
  }, [editedData])

  const updateSorting = async (updatedData: any) => {
    try {
      const response = await updateApiData({
        url: SORT_TIERS_OVERVIEW,
        body: { tier_overview_section: updatedData },
      });
      if (response.status === 200) {
        dispatch(alertSuccess("Updated Successfully"));
      } else {
        dispatch(alertFailure(response?.message));
      }
    } catch (error: any) {
      console.error(`${error?.message || "Error Updating"}`);
      dispatch(alertFailure(`${error?.message || "Error Updating"}`));
    }
  };

  const onDragEnd = (result: any) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items: TierOverview[] = Array.from(tiersOverviewData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      id: item?.id,
      sort_id: index + 1,
    }));
    updateSorting(data);
    setTiersOverviewData(items);
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <CardBody className="p-0">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
              <input type="text" hidden name="id" ref={register()} />
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label
                      htmlFor="basicpill-firstname-input"
                      className="form-label"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input"
                      placeholder="Enter Title"
                      name="title"
                      ref={register({
                        required: "This Field is Required",
                      })}
                    />
                    <FormValidationError
                      errorMessage={errors?.title?.message}
                    />
                  </div>
                </div>
                <Row>
                  <label className="form-label">Tier Icon</label>
                  <Col className="col-12">
                    <ImageUploaderWithForm
                      field="icon"
                      onFileSelect={handleFileSelect}
                      initialSrc={icon}
                    />
                  </Col>
                </Row>
                <div>
                  <button type="submit" className="btn btn-info w-md mt-2">
                    Submit
                  </button>
                </div>
              </form>
            </FormProvider>
          </CardBody>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <Label className="form-label">Tiers Overview Table</Label>
          <Card>
            <CardBody>
              <div>
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Icon</th>

                        <th>Actions</th>
                      </tr>
                    </thead>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <tbody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {tiersOverviewData?.map((item: any, index: any) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <th scope="row">{index + 1}</th>
                                    <td>{item?.title}</td>
                                    <td>
                                      {item?.icon_url && (
                                        <img
                                          width={30}
                                          height={30}
                                          alt={item?.title}
                                          src={item?.icon_url}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      <span className="d-flex">
                                        <button
                                          className="btn btn-info btn-sm me-2"
                                          onClick={() => handleEdit(item)}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => {
                                            setDeleteTierOverViewId(item?.id);
                                            setDeleteModalOpen(true);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        title="Tier Overview"
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        onDeleteClick={handleDelete}
      />
    </div>
  );
};

export default TiersOverview;
