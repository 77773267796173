export const USERS = '/admin/users';
export const USERS_CSV = '/admin/users/download'
export const DELETE_BONUS = '/admin/users/bonuses'
export const SET_BONUS = '/admin/users/bonus'
export const IMPORT_CSV = '/admin/users/bonus/import'
export const ADMINS = '/admin/admins'
export const ADMIN = '/admin/admins/'
export const STAKING_POOL = '/admin/staking-pool'
export const STAKING_POOL_BY_ID = '/admin/staking-pool/'
export const CREATE_STAKING_POOL = '/admin/staking-pool/create'
export const STATISTICS = '/admin/statistic'
export const UPDATE_STATISTICS = '/admin/statistic/update'
export const GIVEAWAY_STATISTICS = '/admin/giveaway-statistic'
export const UPDATE_GIVEAWAY_STATISTICS = '/admin/giveaway-statistic/update'

export const GET_LANDING_REGISTER = '/admin/landing-page/register-section'
export const POST_LANDING_REGISTER = '/admin/landing-page/register-section'
export const UPDATE_LANDING_REGISTER = '/admin/landing-page/update/register-section'
export const NEED_HELP_EMAIL = '/admin/need-help/support-email'
export const NEED_HELP_FAQ = '/admin/need-help/faq'
export const NEED_HELP_GUIDE = '/admin/need-help/guide'
export const SOCIAL_MEDIA_LINKS = '/admin/social-media-link'
export const SOCIAL_MEDIA_TYPES = '/admin/social-media-type'
export const THEME_OPTIONS = '/admin/theme-option'
export const GOOGLE_FORM = '/admin/landing-page/googleform-section'
export const SORT_FAQ = '/admin/faq/sort'
export const SORT_REGISTER = '/admin/landing-page/register-section'
export const SORT_FEATURE = '/admin/landing-page/feature-section'
export const SORT_TIER = '/admin/config/tier/sort'
export const SORT_TIER_OVERVIEW = '/admin/config/tier-overview/sort'
export const SORT_TIER_BENEFIT = '/admin/config/tier-benefits/sort'
export const SORT_SOCIAL = '/admin/social-media-link'
export const SORT_HELP_GUIDE = '/admin/need-help/guide/sort'
export const SORT_HELP_FAQ = '/admin/need-help/faq/sort'


//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//LIST POOL
export const CAMPAIGNS = "/campaigns"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"
export const ADD_INVOICE = "/add/invoice"
export const DELETE_INVOICE = "/delete/invoice"
export const UPDATE_INVOICE = "/update/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"

export const GET_MARKET_OVERVIEW = "GET_MARKET_OVERVIEW"
export const GET_MARKET_OVERVIEW_SUCCESS = "GET_MARKET_OVERVIEW_SUCCESS"
export const GET_MARKET_OVERVIEW_FAIL = "GET_MARKET_OVERVIEW_FAIL"

export const GET_WALLENT_BALANCE = "GET_WALLENT_BALANCE"

export const GET_WALLENT_BALANCE_ALL_DATA = "GET_MARKET_ALL_DATA"

export const GET_Invested_Overview = "GET_Invested_Overview"


//Kyclist
export const KYC_URL = "/admin/kyc-users";


//Landing page 
export const SET_LANDING_PAGE = "/admin/submit-landing-page";
export const GET_LANDING_PAGE = "/admin/landing-page";


//Register page
export const POST_REGISTER = "admin/landing-page/register-section";
export const GET_REGISTER = "admin/landing-page/register-section";
export const UPDATE_REGISTER = "admin/landing-page/update/register-section";
export const DELETE_REGISTER = "admin/landing-page/register-section";


//FAQS
export const GET_FAQ_CATEGORIES = "admin/faq/question-categories";
export const POST_SUBMIT_FAQ = "admin/faq/submit-faq";
export const UPDATE_FAQ = "admin/faq/update";
export const GET_FAQS = "admin/faq/get-faqs";
export const DELETE_FAQS = "admin/faq/delete";
// terms and service
export const TERMS_AND_SERVICES = "admin/termsofservice";
export const GET_TERMS_AND_SERVICES = "admin/termsofservice";
// Privay policy
export const PRIVACY_POLICY = "admin/privacy-policy/submit";
export const GET_PRIVACY_POLICY = "admin/privacy-policy/get";

//Feature Section
export const GET_LANDING_FEATURE = "/admin/landing-page/feature-section";
export const POST_LANDING_FEATURE = "/admin/landing-page/feature-section";
export const UPDATE_LANDING_FEATURE = "admin/landing-page/update/feature-section";
export const DELETE_LANDING_FEATURE = "/admin//landing-page/feature-section";


//Tiers
export const GET_CONFIG_TIER = "/admin/config/tier"
export const POST_CONFIG_TIER = "/admin/config/tier";
// Menu
export const POST_MENU = "admin/menu/create";
export const UPDATE_MENU = "admin/menu/update";
export const GET_MENU = "admin/menu/list";
export const DELETE_MENU = "admin/menu/delete/";
export const SORT_MENU = "admin/menu/sort"

//pools
export const CREATE_POOL = "/admin/pool/create"
export const PARTICIPANTS = "/participants"
export const DEPLOYSUCCESS = "/deploy-success"
export const UPDATEPOOL = "/update"
export const CHANGE_PUBLIC_WINNER_STATUS = "change-public-winner-status"
export const IMPORT_WINNERS = "winners/import"
export const WINNERS = "winners"
export const DELETE = "delete"
export const DELETEALL = "delete-all"
export const FCFS = "fcfs"
export const CHANGEDISPLAY = "change-display"

//TiersOverview
export const GET_CONFIG_TIER_OVERVIEW = "/admin/config/tier-overview";
export const POST_CONFIG_TIER_OVERVIEW = "/admin/config/tier-overview";

//StakingInfo
export const GET_CONFIG_STAKING_INFO = "/admin/config/staking-info";
export const POST_CONFIG_STAKING_INFO = "admin/config/staking-info";


//TierBenefits
export const GET_CONFIG_TIER_BENEFIT = "/admin/config/tier-benefits";
export const POST_CONFIG_TIER_BENEFIT = "admin/config/tier-benefits";
