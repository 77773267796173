import React from "react";
import useStyles from "../style";
import FormControl from "@material-ui/core/FormControl";

function StaticATHRoi(props: any) {
  const classes = useStyles();
  const { register, poolDetail } = props;

  return (
    <>
      <br />
      <FormControl component="fieldset">
        <label className={classes.formControlLabel}>ATH Price</label>
        <input
          type="text"
          name="staticAthRoi"
          defaultValue={poolDetail?.static_ath_roi}
          ref={register({})}
          className={classes.formControlInput}
        />
      </FormControl>
    </>
  );
}

export default StaticATHRoi;
