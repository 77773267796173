import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  Row,
  Table,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import FormEditor from "../../components/Base/FormEditor";
import { removeHtmlTags, truncateText } from "../../helper/functions";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import {
  DELETE_FAQ_QUESTIONS,
  GET_FAQ_CATEGORY_DATA,
  GET_FAQ_QUESTIONS,
  SAVE_FAQ_QUESTIONS,
  SORT_FAQ_QUESTIONS,
  UPDATE_FAQ_QUESTIONS,
} from "../../request/config/Urls";
import {
  deleteApiData,
  fetchApiData,
  saveApiData,
  updateApiData,
} from "../../request/config/index";
import FormValidationError from "../../components/Base/FormValidationError";
import * as apiHelper from "../../request/apiHelper";

const QuestionCategory = () => {
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [editorData, setEditorData] = useState("");
  const [questionCategory, setQuestionCategory] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [idToDelete, setIdToDelete] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [mode, setMode] = useState("add"); // 'add' or 'edit'
  const [editingItem, setEditingItem] = useState<any>(null);
  const [submitClicked, setSubmitClicked] = useState(false);

  const dispatch = useDispatch();

  const getQuestionCategory = async () => {
    const url = GET_FAQ_CATEGORY_DATA;
    try {
      const response = await fetchApiData({ url });
      setQuestionCategory(response.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getQuestionList = async () => {
    const url = GET_FAQ_QUESTIONS;
    try {
      const response = await fetchApiData({ url });
      Object.keys(response.data).forEach((item: any) => {
        setValue(item, response.data[item]);
      });
      setQuestionList(response.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const UpdateQuestionList = async (id: number, data: any) => {
    const url = `${UPDATE_FAQ_QUESTIONS}/${id}`;
    try {
      return await saveApiData({ url, body: data });
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getQuestionCategory();
    getQuestionList();
  }, []);

  const onSubmit = async (data: any) => {
    if (editorData?.length <= 0) {
      return;
    }
    if (data.faq_category_id && data.faq_category_id !== "") {
      data.faq_category_id = Number(data.faq_category_id);
    }
    try {
      if (mode === "edit" && editingItem) {
        await UpdateQuestionList(editingItem.id, {
          ...data,
          answer: editorData,
        });
        setMode("add");
        dispatch(alertSuccess("Updated Successfully"));
        setEditingItem(null);
        setEditorData("");
        reset();
        setSubmitClicked(false);
        getQuestionList();
      } else {
        const completeData = {
          question: data?.question,
          faq_category_id: data?.faq_category_id,
          answer: editorData,
          sort_id: questionList.length + 1,
        };

        const url = SAVE_FAQ_QUESTIONS;
        await saveApiData({ url, body: completeData });
        dispatch(alertSuccess("Saved Successfully"));
        setEditorData("");
        reset();
        setSubmitClicked(false);
        getQuestionList();
      }
    } catch (error: any) {
      dispatch(alertFailure(`${error?.message || "Error posting data:"}`));
      console.error("API Error:", error.message);
    }
  };

  const toggleDeleteModal = async (id: any) => {
    setIdToDelete(id);
    setDeleteModalOpen(!deleteModalOpen);
  };

  const handleDelete = async () => {
    try {
      const url = `${DELETE_FAQ_QUESTIONS}/${idToDelete}`;
      const response = await deleteApiData({ url });
      if (response.status === 200) {
        await getQuestionList();
        dispatch(alertSuccess("Delete Successfully"));
        setDeleteModalOpen(false);
      }
      setDeleteModalOpen(false);
    } catch (error: any) {
      console.error(`${error?.message || "Error deleting data:"}`);
      dispatch(alertFailure(`${error?.message || "Error deleting data:"}`));
    }
  };

  const startEdit = (item: any) => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setMode("edit");
    setEditingItem(item);

    Object.keys(item).forEach((key) => {
      if (key === "answer") {
        setEditorData(item[key]);
      } else {
        setValue(key, item[key]);
      }
    });
  };

  const updateSorting = async (updatedData: any) => {
    try {
      const url = SORT_FAQ_QUESTIONS;
      const response = await updateApiData({
        url,
        body: {
          faq: updatedData,
        },
      });
      if (response.status === 200) {
        dispatch(alertSuccess("Updated Successfully"));
      } else {
        dispatch(alertFailure(response?.message));
      }
    } catch (error: any) {
      console.error(`${error?.message || "Error Updating"}`);
      dispatch(alertFailure(`${error?.message || "Error Updating"}`));
    }
  };

  const onDragEnd = (result: any) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items = Array.from(questionList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const categories = items.map((item: any, index) => ({
      id: item?.id,
      sort_id: index + 1,
    }));
    updateSorting(categories);
    setQuestionList(items);
  };

  return (
    <React.Fragment>
      <div>
        <Col xs={12}>
          <Card>
            <CardBody className="p-4">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setSubmitClicked(true);

                  handleSubmit(onSubmit)();
                }}
                onReset={reset}
              >
                <div className="mb-3">
                  <div className="col-lg-4">
                    <Label className="form-Label">
                      Select Question Category
                    </Label>
                    <select
                      name="faq_category_id"
                      className="form-select"
                      ref={register({
                        required: "Please select a category",
                      })}
                    >
                      <option value="">Select</option>
                      {questionCategory.map((category: any) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                    <FormValidationError
                      errorMessage={errors?.faq_category_id?.message}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-firstname-input"
                        className="form-label"
                      >
                        Enter Question
                      </label>
                      <input
                        type="text"
                        name="question"
                        className="form-control"
                        id="basicpill-firstname-input"
                        placeholder="Enter Question"
                        ref={register({
                          required: "Question is Required",
                        })}
                      />
                      <FormValidationError
                        errorMessage={errors?.question?.message}
                      />
                    </div>
                  </div>
                </div>
                <Row>
                  <Col lg={12}>
                    <h4 className="card-title mb-1">Enter Answer</h4>
                    <FormEditor data={editorData} setData={setEditorData} />
                  </Col>
                </Row>
                <div>
                  <button type="submit" className="btn btn-info w-md mt-2">
                    {mode === "edit" ? "Edit" : "Submit"}
                  </button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Row>
          <Col>
            <div>
              <Label>FAQS Table</Label>
            </div>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Question Category</th>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <tbody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {questionList.map((item: any, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.category?.name}</td>{" "}
                                    <td>{item.question}</td>
                                    <td title={removeHtmlTags(item?.answer)}>
                                      {truncateText(
                                        removeHtmlTags(item?.answer),
                                        20
                                      ) ??
                                        "" ??
                                        ""}
                                    </td>
                                    <td>
                                      <span className="d-flex">
                                        <button
                                          className="btn btn-info btn-sm me-2"
                                          onClick={() => startEdit(item)}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() =>
                                            toggleDeleteModal(item.id)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Table>
                </div>
                <Modal isOpen={deleteModalOpen} size="sm">
                  <ModalBody>
                    <div className="mb-3">
                      <p>Do you want to delete this ?</p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Button
                        color="primary"
                        className="me-3"
                        onClick={() => setDeleteModalOpen(false)}
                      >
                        Cancel
                      </Button>
                      <Button color="danger" onClick={handleDelete}>
                        Delete
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default QuestionCategory;
