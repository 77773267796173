import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import ImageUploaderWithForm from "../../components/Base/ImageUploader/ImageUploaderWithForm";
import DeleteModal from "../../components/Base/DeleteModal";
import { truncateText } from "../../helper/functions";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import * as apiHelper from "../../request/apiHelper";
import {
  deleteApiDataWithBody,
  fetchApiData,
  updateApiData,
} from "../../request/config/index";
import {
  DELETE_MENU_LINKS,
  GET_MENU_LINKS,
  SAVE_MENU_LINKS,
  SORT_MENU_LINKS,
  UPDATE_MENU_LINKS,
} from "../../request/config/Urls";

const MenuLinks = () => {
  const [image, setImage]: any = useState("");
  const [menus, setMenusData] = useState<any[]>([]);
  const methods = useForm();
  const [parentMenu, setParentMenu] = useState([]);
  const [headerMenus, setHeaderMenus] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMenu, setDeleteMenu] = useState<any>();
  const [footerMenus, setFooterMenus] = useState([]);
  const [expandedMenus, setExpandedMenus] = useState<Set<number>>(new Set());

  const dispatch = useDispatch();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  const formData = useRef(new FormData());

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetchApiData({ url: GET_MENU_LINKS });
      setMenusData(response.data);
      const header = response.data.filter(
        (item: any) => item.type === "header"
      );
      const footer = response.data.filter(
        (item: any) => item.type === "footer"
      );
      setHeaderMenus(header);
      setFooterMenus(footer);
      const newParentMenu = response.data
        .filter((item: any) => item.is_parent === 1)
        .map((item: any) => ({
          id: item.id,
          name: item.menu_name,
        }));
      setParentMenu(newParentMenu);
    } catch (error: any) {
      console.error(
        `${error?.response?.data?.message || "Error fetching data:"}`
      );
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data:"}`
        )
      );
    }
  };

  const handleFileSelect = (file: any) => {
    setImage(file);
  };

  const toggleMenu = (menuId: number) => {
    setExpandedMenus((prevExpandedMenus) => {
      const newExpandedMenus = new Set(prevExpandedMenus);
      if (newExpandedMenus.has(menuId)) {
        newExpandedMenus.delete(menuId);
      } else {
        newExpandedMenus.add(menuId);
      }
      return newExpandedMenus;
    });
  };

  function toServerData(menu: any) {
    let isParent = menu?.parent_id === "Select" || menu?.parent_id === 0;
    let serverData = {
      ...menu,
      is_parent: isParent ? 1 : 0,
    };

    if (!isParent) {
      serverData.parent_id = parseInt(menu?.parent_id);
    } else {
      delete serverData["parent_id"];
    }

    return serverData;
  }

  const onSubmit = async (event: any) => {
    if (typeof image != "string") {
      event.image = image;
    }
    event = toServerData(event);

    for (const key in event) {
      if (event[key] === "image" && typeof event.image != "string") {
        formData.current.set(key, event[key]);
      } else if (event[key] !== "image") {
        formData.current.set(key, event[key]);
      }
    }
    try {
      if (event.id) {
        let res = await apiHelper.postFile(
          UPDATE_MENU_LINKS + "/" + event.id,
          formData.current
        );
        if (res) {
          reset();
          setImage(null);
          fetchData();
        }
      } else {
        if (event.type === "header" && event.is_parent === 1) {
          const headerParentCount = headerMenus.filter(
            (menu: any) => menu.is_parent === 1
          )?.length;

          if (headerParentCount >= 4) {
            dispatch(
              alertFailure("Only 4 header items can be added as parent menus")
            );
            return;
          }
        }
        if (event.is_parent === 1) {
          formData.current.set("sort_id", (menus?.length + 1).toString());
        } else {
          formData.current.set("sort_id", (99).toString());
        }
        let res = await apiHelper.postFile(SAVE_MENU_LINKS, formData.current);
        if (res) {
          reset();
          setImage(null);
          fetchData();
        }
        if (res.status === 200) {
          dispatch(alertSuccess(res?.message));
        }
      }
    } catch (error: any) {
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Something went wrong"}`
        )
      );
    }
  };

  async function handleDelete() {
    const url = `${DELETE_MENU_LINKS}/${deleteMenu?.id}`;
    try {
      const res = await deleteApiDataWithBody({
        url,
        body: {
          is_parent: deleteMenu?.is_parent,
        },
      });
      if (res) {
        dispatch(alertSuccess("Deleted"));
        fetchData();
      }
    } catch (err) {
      dispatch(alertSuccess("Unable To Delete"));
    }
    setDeleteModalOpen(false);
  }

  function handleEdit(data: any) {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setImage(null);
    Object.keys(data).forEach((item: any) => {
      if (item !== "image") {
        setValue(item, data[item]);
      }
    });
    setImage(data?.icon_url);
  }

  const updateSorting = async (
    updatedData: any,
    is_parent: any,
    parent_id: any
  ) => {
    try {
      const response = await updateApiData({
        url: SORT_MENU_LINKS,
        body: { menus: updatedData, is_parent, parent_id },
      });
      if (response.status === 200) {
        dispatch(alertSuccess("Updated Successfully"));
      } else {
        dispatch(alertFailure(response?.message));
      }
    } catch (error: any) {
      console.error(`${error?.message || "Error Updating"}`);
      dispatch(alertFailure(`${error?.message || "Error Updating"}`));
    }
  };

  const onDragEnd = (result: any) => {
    const { source, destination, type } = result;

    if (!destination) {
      return;
    }

    if (type === "parent") {
      const newMenus = Array.from(menus);
      const [removed] = newMenus.splice(source.index, 1);
      newMenus.splice(destination.index, 0, removed);

      const data = newMenus.map((item, index) => ({
        id: item?.id,
        sort_id: index + 1,
      }));

      updateSorting(data, 1, 0);
      setMenusData(newMenus);
    } else if (type === "child") {
      const parentIndex = menus.findIndex(
        (menu) => menu.id.toString() === source.droppableId.split("-")[2]
      );
      if (parentIndex !== -1) {
        const newChildren = Array.from(menus[parentIndex].children);
        const [removed] = newChildren.splice(source.index, 1);
        newChildren.splice(destination.index, 0, removed);

        const data = newChildren.map((item: any, index) => ({
          id: item?.id,
          sort_id: index + 1,
        }));

        const newMenus = Array.from(menus);
        newMenus[parentIndex].children = newChildren;

        updateSorting(data, 0, newMenus[parentIndex]?.id);
        setMenusData(newMenus);
      }
    }
  };

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="p-4">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    type="text"
                    ref={register({})}
                    name="id"
                    hidden
                    id="basicpill-firstname-input"
                  />
                  <div className="row">
                    <div className="col-md-4 mt-2">
                      <label
                        htmlFor="basicpill-firstname-input"
                        className="form-label"
                      >
                        Menu Title
                      </label>
                      <input
                        type="text"
                        ref={register({
                          required: "This Field is Required",
                        })}
                        name="menu_name"
                        className={`form-control ${
                          errors.menu_name ? "is-invalid" : ""
                        }`}
                        id="basicpill-firstname-input"
                        placeholder="Enter Name"
                      />
                      {errors.menu_name && (
                        <div className="invalid-feedback">
                          {errors.menu_name.message.toString()}
                        </div>
                      )}
                    </div>

                    <div className="col-md-4 mt-2">
                      <Label className="form-Label">Menu Type</Label>
                      <select
                        className={`form-select ${
                          errors.type ? "is-invalid" : ""
                        }`}
                        ref={register({
                          required: "This Field is Required",
                        })}
                        name="type"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="header">Header</option>
                        <option value="footer">Footer</option>
                      </select>
                      {errors.type && (
                        <div className="invalid-feedback">
                          {errors.type.message.toString()}
                        </div>
                      )}
                    </div>

                    <div className="col-md-4 mt-2">
                      <Label className="form-label">Parent Menu</Label>
                      <select
                        className="form-select"
                        ref={register({
                          required: "This Field is Required",
                        })}
                        name="parent_id"
                      >
                        <option>Select</option>
                        {parentMenu &&
                          parentMenu.length > 0 &&
                          parentMenu.map((menu: any, index) => (
                            <option value={menu.id}>{menu?.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-2">
                      <label
                        htmlFor="basicpill-firstname-input"
                        className="form-label"
                      >
                        Enter URL
                      </label>
                      <input
                        type="text"
                        ref={register({
                          required: "This Field is Required",
                        })}
                        name="menu_url"
                        className={`form-control ${
                          errors.menu_url ? "is-invalid" : ""
                        }`}
                        id="basicpill-firstname-input"
                        placeholder="Enter URL"
                      />
                      {errors.menu_url && (
                        <div className="invalid-feedback">
                          {errors.menu_url.message.toString()}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row d-none">
                    <span className="d-none">
                      <Label className="form-label">Is Parent</Label>
                      <div
                        style={{ display: "inline-block", marginLeft: "20px" }}
                      >
                        <input
                          type="checkbox"
                          id="switch1"
                          onChange={() => {}}
                          name="is_parent"
                          ref={register({})}
                        />
                      </div>
                    </span>
                  </div>
                  <div className="col-lg-12 mt-2">
                    <Label className="form-Label">Menu Icon</Label>
                    <ImageUploaderWithForm
                      field="image"
                      onFileSelect={handleFileSelect}
                      initialSrc={image}
                    />
                  </div>

                  <div>
                    <button type="submit" className="btn btn-info w-md mt-5">
                      Submit
                    </button>
                  </div>
                </form>
              </FormProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <Label className="form-label">Header/Footer Menu</Label>
          <Card>
            <CardBody>
              <div>
                <div className="table-responsive">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Menu Icon</th>
                          <th>Name</th>
                          <th>URL</th>
                          <th>Type</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <Droppable droppableId="droppable" type="parent">
                        {(provided) => (
                          <tbody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {menus.map((menu, index) => (
                              <Draggable
                                key={menu.id}
                                draggableId={`parent-${menu.id}`}
                                index={index}
                              >
                                {(provided) => (
                                  <React.Fragment>
                                    <tr
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      onClick={() => {
                                        if (menu.children.length > 0) {
                                          toggleMenu(menu.id);
                                        }
                                      }}
                                    >
                                      <td>
                                        {menu.children.length > 0 && (
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              marginRight: "5px",
                                            }}
                                          >
                                            {expandedMenus.has(menu.id) ? (
                                              <i className="bx bx-caret-down font-size-22"></i>
                                            ) : (
                                              <i className="bx bx-caret-right font-size-22"></i>
                                            )}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <img
                                          src={menu?.icon_url}
                                          width={30}
                                          height={30}
                                          alt=""
                                        />
                                      </td>
                                      <td>{menu?.menu_name}</td>
                                      <td>
                                        <a href={menu?.menu_url}>
                                          {truncateText(menu?.menu_url, 30)}
                                        </a>
                                      </td>
                                      <td>{menu?.type}</td>
                                      <td>
                                        <span className="d-flex">
                                          <button
                                            onClick={() => handleEdit(menu)}
                                            className="btn btn-info btn-sm me-2"
                                          >
                                            Edit
                                          </button>
                                          <button
                                            onClick={() => {
                                              setDeleteModalOpen(true);
                                              setDeleteMenu(menu);
                                            }}
                                            className="btn btn-danger btn-sm"
                                          >
                                            Delete
                                          </button>
                                        </span>
                                      </td>
                                    </tr>
                                    {expandedMenus.has(menu.id) &&
                                      menu.children && (
                                        <Droppable
                                          droppableId={`child-droppable-${menu.id}`}
                                          type="child"
                                        >
                                          {(provided) => (
                                            <tr
                                              ref={provided.innerRef}
                                              {...provided.droppableProps}
                                            >
                                              <td colSpan={6}>
                                                <table className="table mb-0">
                                                  <tbody>
                                                    {menu.children.map(
                                                      (
                                                        childMenu: any,
                                                        childIndex: any
                                                      ) => (
                                                        <Draggable
                                                          key={childMenu.id}
                                                          draggableId={`child-${childMenu.id}`}
                                                          index={childIndex}
                                                        >
                                                          {(provided) => (
                                                            <tr
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}
                                                            >
                                                              <td>
                                                                {childIndex + 1}
                                                              </td>
                                                              <td>
                                                                <img
                                                                  src={
                                                                    childMenu?.icon_url
                                                                  }
                                                                  width={30}
                                                                  height={30}
                                                                  alt=""
                                                                />
                                                              </td>
                                                              <td>
                                                                {
                                                                  childMenu?.menu_name
                                                                }
                                                              </td>
                                                              <td>
                                                                <a
                                                                  href={
                                                                    childMenu?.menu_url
                                                                  }
                                                                >
                                                                  {truncateText(
                                                                    childMenu?.menu_url,
                                                                    30
                                                                  )}
                                                                </a>
                                                              </td>
                                                              <td>
                                                                {
                                                                  childMenu?.type
                                                                }
                                                              </td>
                                                              <td>
                                                                <span className="d-flex">
                                                                  <button
                                                                    onClick={() =>
                                                                      handleEdit(
                                                                        childMenu
                                                                      )
                                                                    }
                                                                    className="btn btn-info btn-sm me-2"
                                                                  >
                                                                    Edit
                                                                  </button>
                                                                  <button
                                                                    onClick={() => {
                                                                      setDeleteModalOpen(
                                                                        true
                                                                      );
                                                                      setDeleteMenu(
                                                                        childMenu
                                                                      );
                                                                    }}
                                                                    className="btn btn-danger btn-sm"
                                                                  >
                                                                    Delete
                                                                  </button>
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          )}
                                                        </Draggable>
                                                      )
                                                    )}
                                                    {provided.placeholder}
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          )}
                                        </Droppable>
                                      )}
                                  </React.Fragment>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </table>
                  </DragDropContext>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        title="Menu Link"
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        onDeleteClick={handleDelete}
      />
    </div>
  );
};

export default MenuLinks;
